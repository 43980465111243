import React, { useReducer } from 'react'
import cn from 'classnames'
import { updateUserData } from 'lib/firebase'
import { useHistory } from 'react-router-dom'

import styles from './style.module.sass'

import NavToolbar from 'components/NavToolbar'
import Axios from 'agilite-utils/axios'
import Enums from 'lib/enums'
import { executeAgiliteRequest } from 'lib/utils'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from '-core/core-reducer'
import { updateUserProfilePic } from 'lib/firebase'
import { Col, Divider, message, Row } from 'antd'
import TextInput from 'components/TextInput'
import ButtonSpinner from 'components/ButtonSpinner'

const ProfileEdit = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const reduxUser = useSelector((state) => state.core.user)

  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    loading: false,
    disableForm: false,
    username: reduxUser.data.username
  })

  const breadcrumbs = [
    {
      title: 'Home',
      url: '/'
    },
    {
      title: 'My Profile',
      url: '/profile'
    },
    {
      title: 'Edit Profile',
      url: '/profile-edit'
    }
  ]

  const handleUpload = (e) => {
    let reader = new FileReader()

    reader.readAsArrayBuffer(e.target.files[0])

    reader.onabort = () => {
      console.log('Aborted Image Upload')
    }
    reader.onload = async () => {
      await uploadProfilePic(reader.result)
    }
    reader.onerror = (e) => {
      console.error(e)
    }
  }

  const uploadProfilePic = async (data) => {
    let axiosRef = null
    let tmpUser = null
    let result = null

    try {
      setState({ loading: true })

      // Fetch data from Agilit-e
      axiosRef = Axios.CancelToken.source()
      result = await executeAgiliteRequest(axiosRef.token, Enums.reqActions.UPLOAD_PROFILE_PIC, null, data)

      tmpUser = JSON.parse(JSON.stringify(reduxUser))
      tmpUser.data.profilePicId = result.data.metadata.publicId

      await updateUserProfilePic(reduxUser.uid, tmpUser.data.profilePicId)
      dispatch(setUser(tmpUser))

      setState({ loading: false })
    } catch (e) {
      setState({ loading: false })
      console.error(e)
    }
  }

  const handleOnFieldChange = (key, value) => {
    try {
      switch (key) {
        default:
          setState({ username: value.replace(/[^\w]/gi, '') })
          break
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleSubmit = async () => {
    let msg = null
    let tmpUser = null

    try {
      // Validate Input fields
      if (!state.username) {
        msg = 'Please provide a Username'
        throw new Error(msg)
      }

      // Update User
      setState({ disableForm: true })

      tmpUser = JSON.parse(JSON.stringify(reduxUser))
      tmpUser.data.username = state.username
      tmpUser.data.profileComplete = true

      await updateUserData(tmpUser.uid, tmpUser.data)
      dispatch(setUser(tmpUser))
      setState({ disableForm: false })

      message.success({
        content: 'Profile successfully updated',
        className: 'message-prompt'
      })

      history.replace('/profile')
    } catch (e) {
      setState({ disableForm: false })
      message.error({
        content: e.message,
        className: 'message-prompt'
      })
    }
  }

  return (
    <div className={styles.page}>
      <NavToolbar breadcrumbs={breadcrumbs} backTitle='Back to Profile' backUrl='/profile' />
      <div className={cn('section-pt20', styles.section)}>
        <div className={styles.top}>
          <h5 className={cn('h5', styles.title)}>Edit Profile</h5>
        </div>
        <Row>
          <Col
            xs={{ span: 20, offset: 2 }}
            sm={{ span: 16, offset: 4 }}
            md={{ span: 10, offset: 7 }}
            lg={{ span: 6, offset: 9 }}
            xl={6}
          >
            <div className={styles.user}>
              <div className={styles.avatar}>
                <img
                  src={
                    reduxUser.data.profilePicId
                      ? `https://apidemo.agilite.io/files/${reduxUser.data.profilePicId}`
                      : '/images/icons/metability-profile-default-icon-100.jpg'
                  }
                  alt='Avatar'
                />
              </div>
              <div className={styles.details}>
                <div className={styles.stage}>Profile Picture</div>
                <div className={styles.text}>
                  We recommend an image of at least 400x400. Gifs work too{' '}
                  <span role='img' aria-label='hooray'>
                    🙌
                  </span>
                </div>
                <div className={styles.file}>
                  <label
                    className={cn('button-stroke button-small', state.loading ? styles.buttonDisabled : styles.button)}
                  >
                    <input
                      disabled={state.loading}
                      className={styles.load}
                      type='file'
                      onChange={(e) => handleUpload(e)}
                      accept='.png,.jpeg,.jpg,.gif'
                    />
                    {state.loading ? 'Uploading...' : 'Upload'}
                  </label>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Divider className={styles.divider}>User Details</Divider>
          </Col>
        </Row>
        <Row>
          <Col
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 10, offset: 7 }}
            lg={{ span: 8, offset: 8 }}
            xl={{ span: 6, offset: 9 }}
            style={{ textAlign: 'center' }}
          >
            <TextInput
              className={styles.field}
              name='username'
              placeholder='Username'
              value={state.username}
              required={true}
              onChange={(e) => handleOnFieldChange(e.target.name, e.target.value)}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col xs={{ span: 22, offset: 1 }} md={{ span: 12, offset: 6 }} style={{ textAlign: 'center' }}>
            <button className={cn('button button-small')} onClick={handleSubmit} disabled={state.disableForm}>
              <ButtonSpinner spinning={state.disableForm} />
              Update Profile
            </button>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ProfileEdit
