import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import 'styles/app.sass'

import Wrapper from './Wrapper'
import Home from 'screens/Home'
// import Academy from 'screens/Academy'
import CourseDetail from 'screens/CourseDetail'
import CourseLesson from 'screens/CourseLesson'
// import ComingSoon from 'screens/ComingSoon'
import LoginUser from 'screens/LoginUser'
import Quiz from 'screens/Quiz/-main'
// import About from 'screens/About'

import HowItWorks from 'screens/HowItWorks'
// import FAQ from 'screens/Faq'
// import Arena from 'screens/Arena'
// import Events from 'screens/Events'
// import Marketplace from 'screens/Marketplace'
import Profile from 'screens/Profile'
import ProfileEdit from 'screens/ProfileEdit'
import CourseOverview from 'screens/CourseOverview'
import EventsOverview from 'screens/EventsOverview'

function Routes() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path='/'
          render={() => (
            <Wrapper>
              <Home />
            </Wrapper>
          )}
        />
        <Route
          exact
          path='/register'
          render={() => (
            <Wrapper>
              <LoginUser />
            </Wrapper>
          )}
        />
        <Route
          exact
          path='/login'
          render={() => (
            <Wrapper>
              <LoginUser />
            </Wrapper>
          )}
        />
        <Route
          exact
          path='/courses/:courseId/overview'
          render={() => (
            <Wrapper>
              <CourseOverview />
            </Wrapper>
          )}
        />
        {/* <Route
          exact
          path='/academy'
          render={() => (
            <Wrapper>
              <Academy />
            </Wrapper>
          )}
        /> */}
        <Route
          exact
          path='/courses/:courseId'
          render={() => (
            <Wrapper>
              <CourseDetail />
            </Wrapper>
          )}
        />
        <Route
          exact
          path='/courses/:courseId/levels/:levelId/lessons/:lessonId'
          render={() => (
            <Wrapper>
              <CourseLesson />
            </Wrapper>
          )}
        />
        <Route
          exact
          path='/courses/:courseId/levels/:levelId/quiz'
          render={() => (
            <Wrapper>
              <Quiz />
            </Wrapper>
          )}
        />
        <Route
          exact
          path='/events/:eventId/overview'
          render={() => (
            <Wrapper>
              <EventsOverview />
            </Wrapper>
          )}
        />
        {/* <Route
          exact
          path='/arena'
          render={() => (
            <Wrapper>
              <Arena />
            </Wrapper>
          )}
        />
        <Route
          exact
          path='/events'
          render={() => (
            <Wrapper>
              <Events />
            </Wrapper>
          )}
        /> */}
        {/* <Route
          exact
          path='/marketplace'
          render={() => (
            <Wrapper>
              <Marketplace />
            </Wrapper>
          )}
        />
        <Route
          exact
          path='/comingsoon'
          render={() => (
            <Wrapper>
              <ComingSoon />
            </Wrapper>
          )}
        /> */}
        {/* <Route
          exact
          path='/about'
          render={() => (
            <Wrapper>
              <About />
            </Wrapper>
          )}
        /> */}
        <Route
          exact
          path='/howitworks'
          render={() => (
            <Wrapper>
              <HowItWorks />
            </Wrapper>
          )}
        />
        {/* <Route
          exact
          path='/gettingstarted'
          render={() => (
            <Wrapper>
              <Home />
            </Wrapper>
          )}
        /> */}
        <Route
          exact
          path='/alphaprogram'
          render={() => (
            <Wrapper>
              <Home />
            </Wrapper>
          )}
        />
        {/* <Route
          exact
          path='/faq'
          render={() => (
            <Wrapper>
              <FAQ />
            </Wrapper>
          )}
        /> */}
        <Route
          exact
          path='/profile'
          render={() => (
            <Wrapper>
              <Profile />
            </Wrapper>
          )}
        />
        <Route
          exact
          path='/profile-edit'
          render={() => (
            <Wrapper>
              <ProfileEdit />
            </Wrapper>
          )}
        />
      </Switch>
    </Router>
  )
}

export default Routes
