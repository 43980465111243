/* eslint-disable no-loop-func */
import Axios from 'agilite-utils/axios'
import Enums from './enums'

export const processPhase = (quizFlow, gameData, userData, isStarting) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let newGameData = Object.assign({}, gameData)

      try {
        // Determine if Game is starting or going into next Turn/Phase
        if (isStarting || (newGameData.turn === 1 && !newGameData.stepKey)) {
          // Start of Game - Initialize Game Data
          await _initGame(quizFlow, newGameData, userData)
        } else {
          // Determine Next Step and Game Data
          await _updateGame(quizFlow, newGameData, userData)
        }

        resolve(newGameData)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const moveCard = (gameCardId, fromSection, toSection) => {
  return new Promise((resolve, reject) => {
    let cardIndex = null

    try {
      // Find Card from From Section and Move to To Section
      cardIndex = fromSection.findIndex((entry) => entry.gameCardId === gameCardId)
      if (cardIndex === -1) throw new Error(`No Card found in From Section for ID: ${gameCardId}`)

      toSection.push(fromSection[cardIndex])
      fromSection.splice(cardIndex, 1)

      resolve()
    } catch (e) {
      reject(e)
    }
  })
}

export const canCardActionOccur = (card, gameData) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let result = null

      try {
        if (card.actionPoints > gameData.actionPointsAvailable) {
          result = 'Insufficient Action Points'
        }

        resolve(result)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const initCards = (cardData, gameCards, cardTypes) => {
  return new Promise((resolve, reject) => {
    let result = []
    let gameCard = null
    let cardType = null
    let newEntry = null
    let cardId = null

    try {
      for (const x in cardData) {
        cardId = cardData[x]

        gameCard = gameCards.find((entry) => entry.id === cardId)
        if (!gameCard) throw new Error(`No Game Card found for ID: ${cardId}`)

        cardType = cardTypes.find((entry) => entry.id === gameCard.cardTypeId)
        if (!cardType) throw new Error(`No Card Type found for ID: ${gameCard.cardTypeId}`)

        newEntry = JSON.parse(JSON.stringify(cardType))
        newEntry.cardTypeId = cardType.id
        newEntry.gameCardId = gameCard.id
        newEntry.addons = []
        newEntry.power = {}
        newEntry.isCardAdded = false

        delete newEntry.id

        result.push(newEntry)
      }

      resolve(result)
    } catch (e) {
      reject(e)
    }
  })
}

export const shuffleArray = (data) => {
  return new Promise((resolve, reject) => {
    try {
      for (let i = data.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1)) // random index from 0 to i

        // swap elements array[i] and array[j]
        // we use "destructuring assignment" syntax to achieve that
        // you'll find more details about that syntax in later chapters
        // same can be written as:
        // let t = array[i]; array[i] = array[j]; array[j] = t
        ;[data[i], data[j]] = [data[j], data[i]]
      }

      resolve(data)
    } catch (e) {
      reject(e)
    }
  })
}

export const prepCardDeck = (userResourceCards, userActionCards) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let result = null
      try {
        result = JSON.parse(JSON.stringify(userResourceCards))
        result = result.concat(userActionCards)

        resolve(result)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const updateActionPoints = (actionPoints, action, gameData, userData) => {
  return new Promise((resolve, reject) => {
    let result = null

    try {
      result = {
        actionPointsUsed: userData.actionPointsUsed,
        actionPointsAvailable: gameData.actionPointsAvailable
      }

      switch (action) {
        case 'add':
          result.actionPointsUsed = result.actionPointsUsed - actionPoints
          result.actionPointsAvailable = result.actionPointsAvailable + actionPoints
          break
        case 'subtract':
          result.actionPointsUsed = result.actionPointsUsed + actionPoints
          result.actionPointsAvailable = result.actionPointsAvailable - actionPoints
          break
      }

      resolve(result)
    } catch (e) {
      reject(e)
    }
  })
}

export const abbreviateWalletAddress = (ethAddress) => {
  return new Promise((resolve, reject) => {
    try {
      const tmpValStart = ethAddress.substring(0, 14)
      const tmpValEnd = ethAddress.slice(-4)
      const tmpValFinal = `${tmpValStart}...${tmpValEnd}`
      resolve(tmpValFinal)
    } catch (e) {
      reject(e)
    }
  })
}

export const formatNumber = (x) => {
  var parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return parts.join('.')
}

export const getQuizQuestion = (questions) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let result = null
      let tmpQuestions = null

      try {
        // Filter and return Unanswered questions only
        tmpQuestions = questions.filter((item) => item.answered === '')

        if (tmpQuestions.length > 0) {
          // Randomise result
          tmpQuestions = JSON.parse(JSON.stringify(tmpQuestions))

          tmpQuestions = await shuffleArray(tmpQuestions)

          // For each question, randomise answers
          for (const question of tmpQuestions) {
            question.answers = await shuffleArray(question.answers)
          }

          // Return first question
          result = tmpQuestions[0]
        }

        resolve(result)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const processQuizAnswer = (questions, questionId, answerId) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let tmpQuestions = null
      let question = null

      try {
        // Create new instance of questions
        tmpQuestions = JSON.parse(JSON.stringify(questions))

        // Find relevant question and provide answer
        question = tmpQuestions.find((item) => item.id === questionId)
        question.answered = answerId ? answerId : '0'

        // TODO: We need to update the Quiz Score Board

        resolve(tmpQuestions)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const calculateAnswerPoints = (questions) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let result = {
        questionsTotal: 0,
        questionsCorrect: 0,
        skillPoints: 0
      }

      try {
        // Find relevant question and provide answer
        questions = questions.filter((item) => item.answered !== '')
        result.questionsTotal = questions.length

        for (const question of questions) {
          if (question.answered === question.correct) {
            result.questionsCorrect++
            result.skillPoints += question.skillPoints
          }
        }

        resolve(result)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const executeAgiliteRequest = (cancelToken, reqAction, headerValue, bodyData) => {
  let config = null

  try {
    config = {
      method: Enums.reqTypes.POST,
      url: process.env.REACT_APP_NODE_RED_URL,
      headers: {},
      data: bodyData,
      cancelToken
    }

    config.headers[Enums.reqHeaders.CONTENT_TYPE] = Enums.reqHeaders.CONTENT_JSON
    config.headers[Enums.reqHeaders.API_KEY] = process.env.REACT_APP_AGILITE_API_KEY
    config.headers[Enums.reqHeaders.ACTION] = reqAction

    // Add additional headers based on Req Type
    switch (reqAction) {
      case Enums.reqActions.GET_QUIZ_DATA:
      case Enums.reqActions.GET_QUIZ_QUESTIONS:
        config.headers[Enums.reqHeaders.TEMPLATE_KEY] = headerValue
        break
      case Enums.reqActions.GET_LESSON:
        config.headers[Enums.reqHeaders.TIER_KEY] = headerValue
        break
      case Enums.reqActions.UPLOAD_PROFILE_PIC:
        config.headers[Enums.reqHeaders.CONTENT_TYPE] = 'application/octet-stream'
        break
    }

    return Axios.request(config)
  } catch (e) {
    console.error(e)
  }
}

export const sortCatalogueSections = (sectionArray, sectionKey) => {
  return new Promise((resolve, reject) => {
    let tmpArray1 = []
    let tmpArray2 = []
    let result = []

    try {
      tmpArray1 = sectionArray.filter((item) => item.custom.catalogueSections[sectionKey].index !== 0)
      if (tmpArray1.length > 0) {
        tmpArray2 = sectionArray.filter((item) => item.custom.catalogueSections[sectionKey].index === 0)

        tmpArray1 = tmpArray1.sort(
          (a, b) =>
            parseFloat(a.custom.catalogueSections[sectionKey].index) -
            parseFloat(b.custom.catalogueSections[sectionKey].index)
        )

        result = tmpArray1.concat(tmpArray2)
      } else {
        result = sectionArray
      }

      resolve(result)
    } catch (e) {
      reject(e)
    }
  })
}

export const fetchNFTsFromBlockchain = (Web3Api, courseProviders) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let nfts = []
      let options = null
      let data = null
      let provider = null
      let nft = null

      try {
        // Search Blochains for NFTS
        for (const providerId of Object.keys(courseProviders)) {
          provider = courseProviders[providerId]

          for (const contract of provider.nftContracts) {
            options = {
              chain: contract.chainId,
              token_address: contract.address
            }

            data = await Web3Api.account.getNFTsForContract(options)

            for (const entry of data.result) {
              nft = {
                provider: provider.name,
                providerLogo: provider.logoUrl,
                metadata: entry.metadata,
                contractAddress: entry.token_address,
                tokenId: entry.token_id,
                tokenUri: entry.token_uri
              }

              nfts.push(nft)
            }
          }
        }

        resolve(nfts)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const fetchEventNFTsFromBlockchain = (Web3Api, provider, chainId, contractAddress) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let nfts = []
      let options = null
      let data = null
      let nft = null

      try {
        // Search Blochains for NFTS
        options = {
          chain: chainId,
          token_address: contractAddress
        }

        data = await Web3Api.account.getNFTsForContract(options)

        for (const entry of data.result) {
          nft = {
            provider: provider.name,
            providerLogo: provider.logoUrl,
            metadata: entry.metadata,
            contractAddress: entry.token_address,
            tokenId: entry.token_id,
            tokenUri: entry.token_uri
          }

          nfts.push(nft)
        }

        resolve(nfts)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const processNFTs = (nfts, userId) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let result = null

      try {
        result = await Axios.request({
          method: 'post',
          url: `${process.env.REACT_APP_API_SERVER_URL}/user/processNFTs`,
          headers: {
            'api-key': process.env.REACT_APP_AGILITE_API_KEY
          },
          data: { nfts, userId }
        })

        result = result.data
        resolve(result)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const processEventRewards = (nfts, userId) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let result = null

      try {
        result = await Axios.request({
          method: 'post',
          url: `${process.env.REACT_APP_API_SERVER_URL}/events/processRewards`,
          headers: {
            'api-key': process.env.REACT_APP_AGILITE_API_KEY
          },
          data: { nfts, userId }
        })

        result = result.data
        resolve(result)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

// This is useful code when wanting to shuffle and manipulate arrays
// export const prepCardDeck = (userResourceCards, userActionCards) => {
//   return new Promise((resolve, reject) => {
//     ;(async () => {
//       let result = null
//       let resourceCard = null

//       try {
//         result = JSON.parse(JSON.stringify(userResourceCards))

//         // Remove the 1st Resource Card
//         resourceCard = result.shift()

//         // Combine rest of Resource and Action Cards into 1
//         result = result.concat(userActionCards)
//         result = await shuffleArray(result)

//         // Insert Resource Card as 1st card
//         result = [resourceCard].concat(result)

//         resolve(result)
//       } catch (e) {
//         reject(e)
//       }
//     })()
//   })
// }

// export const prepUserHand = (userCardDeck, userHandCardsStart) => {
//   return new Promise((resolve, reject) => {
//     let result = null

//     try {
//       result = userCardDeck.slice(0, userHandCardsStart)
//       userCardDeck.splice(0, userHandCardsStart)
//       resolve(result)
//     } catch (e) {
//       reject(e)
//     }
//   })
// }

// PRIVATE FUNCTIONS
const _initGame = (quizFlow, gameData, userData) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let step = null
      let option = null

      try {
        // Get First Step and Option and update Game Data
        step = await _getFirstStep(quizFlow.key, quizFlow.processSteps)
        option = await _getFirstOption(step.key, step.stepOptions)
        await _updateGameData(gameData, userData, quizFlow.keywords, step, option)

        resolve()
      } catch (e) {
        reject(e)
      }
    })()
  })
}

const _updateGame = (quizFlow, gameData, userData) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let step = null
      let option = null
      // let resourceCards = null

      try {
        // Get Current Step and Option
        step = await _getCurrentStep(quizFlow.key, quizFlow.processSteps, gameData.stepKey)
        option = await _getCurrentOption(step.key, step.stepOptions, gameData.optionKey)

        // TODO: Validate that we can move to the next step
        // resourceCards = userData.cardDeck.find((entry) => entry.cardType === 'resource' && entry.isCardAdded)

        // Move to Next Step and Update Game Data
        step = quizFlow.processSteps.find((entry) => entry.key === option.nextStep)
        option = await _getFirstOption(step.key, step.stepOptions)

        await _updateGameData(gameData, userData, quizFlow.keywords, step, option)

        resolve()
      } catch (e) {
        reject(e)
      }
    })()
  })
}

const _getFirstStep = (flowKey, processSteps) => {
  return new Promise((resolve, reject) => {
    let result = null

    try {
      if (processSteps.length === 0) throw new Error(`No Steps found for Flow: ${flowKey}`)
      result = processSteps.find((entry) => entry.stepType === 'First Step')
      if (!result) throw new Error(`First Step cannot be found for Flow: ${flowKey}`)
      resolve(result)
    } catch (e) {
      reject(e)
    }
  })
}

const _getCurrentStep = (flowKey, processSteps, stepKey) => {
  return new Promise((resolve, reject) => {
    let result = null

    try {
      if (processSteps.length === 0) throw new Error(`No Steps found for Flow: ${stepKey}`)
      result = processSteps.find((entry) => entry.key === stepKey)
      if (!result) throw new Error(`Current Step (${stepKey}) cannot be found for Flow: ${flowKey}`)
      resolve(result)
    } catch (e) {
      reject(e)
    }
  })
}

const _getFirstOption = (stepKey, stepOptions) => {
  return new Promise((resolve, reject) => {
    let result = null

    try {
      if (stepOptions.length === 0) throw new Error(`No Step Options found for Step: ${stepKey}`)
      result = stepOptions[0]
      resolve(result)
    } catch (e) {
      reject(e)
    }
  })
}

const _getCurrentOption = (stepKey, stepOptions, optionKey) => {
  return new Promise((resolve, reject) => {
    let result = null

    try {
      if (stepOptions.length === 0) throw new Error(`No Step Options found for Step: ${stepKey}`)
      result = stepOptions.find((entry) => entry.key === optionKey)
      if (!result) throw new Error(`Step Option (${optionKey}) cannot be found for Step: ${stepKey}`)
      resolve(result)
    } catch (e) {
      reject(e)
    }
  })
}

const _updateGameData = (gameData, userData, bpmKeywords, step, option) => {
  return new Promise((resolve, reject) => {
    try {
      gameData.stepKey = step.key
      gameData.stepName = step.name
      gameData.optionKey = option.key
      gameData.optionName = option.name
      gameData.timeStart = Date.now()

      // Add Keyword Values from BPM Profile
      for (const keyword of bpmKeywords) {
        switch (keyword.label) {
          case '': // These props are not numeric values
            gameData[keyword.label] = keyword.value
            break
          default:
            gameData[keyword.label] = parseInt(keyword.value)
        }
      }

      // Add Keyword Values from Step
      for (const keyword of step.keywords) {
        switch (keyword.label) {
          case 'actionButtonTitle': // These props are not numeric values
            gameData[keyword.label] = keyword.value
            break
          default:
            gameData[keyword.label] = parseInt(keyword.value)
        }
      }

      // Determine Action Points Usage
      if (gameData.turn === 1) {
        gameData.actionPointsForTurn = gameData.actionPointsStart
      } else {
        gameData.actionPointsForTurn = gameData.actionPointsStart + gameData.actionPointsIncrement * gameData.turn
      }

      gameData.actionPointsAvailable = gameData.actionPointsForTurn - userData.actionPointsUsed

      resolve()
    } catch (e) {
      reject(e)
    }
  })
}
