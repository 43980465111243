import React from 'react'
import cn from 'classnames'

import styles from './style.module.sass'

import Enums from 'lib/enums'
import { Col, Row } from 'antd'

const LoginOptions = ({ loginType, onSelectOption }) => {
  let title = null
  let emailLabel = null

  switch (loginType) {
    case 'register':
      title = 'Sign Up using the following methods'
      emailLabel = 'Quick Email Sign Up'
      break
    default:
      title = 'Sign In using the following methods'
      emailLabel = 'Quick Email Sign In'
  }

  return (
    <>
      <Row justify='center' style={{ marginTop: 20 }}>
        <Col xs={{ span: 24 }}>
          <div className={styles.top}>
            <h5 className={cn('h5', styles.title)}>{title}</h5>
          </div>
        </Col>
      </Row>
      <Row justify='center'>
        <Col xs={{ span: 16 }} md={{ span: 10 }} lg={{ span: 8 }} xl={{ span: 4 }}>
          <div className={styles.btns}>
            <button className={cn('button', styles.button)} onClick={() => onSelectOption(Enums.loginFacets.FACEBOOK)}>
              <img
                src='/images/icons/facebook-icon-100.png'
                alt='Facebook'
                style={{ width: 25, marginLeft: -15, marginRight: 5 }}
              />
              Facebook
            </button>
          </div>
        </Col>
      </Row>
      <Row justify='center'>
        <Col xs={{ span: 16 }} md={{ span: 10 }} lg={{ span: 8 }} xl={{ span: 4 }}>
          <div className={styles.btns}>
            <button className={cn('button', styles.button)} onClick={() => onSelectOption(Enums.loginFacets.GOOGLE)}>
              <img
                src='/images/icons/google-icon-100.png'
                alt='Google'
                style={{ width: 25, marginLeft: -15, marginRight: 5 }}
              />
              Google
            </button>
          </div>
        </Col>
      </Row>
      <Row justify='center'>
        <Col xs={{ span: 16 }} md={{ span: 10 }} lg={{ span: 8 }} xl={{ span: 4 }}>
          <div className={styles.btns}>
            <button
              className={cn('button', styles.button)}
              onClick={() => onSelectOption(Enums.loginFacets.EMAIL_LINK)}
            >
              <img
                src='/images/icons/email-icon-100.png'
                alt='Email Link'
                style={{ width: 25, marginLeft: -15, marginRight: 5 }}
              />
              {emailLabel}
            </button>
          </div>
        </Col>
      </Row>
      <Row justify='center' style={{ marginBottom: 10 }}>
        <Col xs={22} sm={22} md={12} lg={10} xl={8}>
          {loginType === 'register' ? (
            <div className={styles.note}>
              By clicking <strong>Submit</strong>, you agree to our <strong>Terms</strong>, <strong>Data Policy</strong>{' '}
              &amp; <strong>Cookie Policy</strong>. You may receive Email notifications from us &amp; can opt out at any
              time.
            </div>
          ) : (
            <div className={styles.note}>
              {`Should you be unsure of how you previously logged in, select '${emailLabel}'.`}
            </div>
          )}
        </Col>
      </Row>
    </>
  )
}

export default LoginOptions
