import React, { useEffect } from 'react'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Col, Row } from 'antd'

import styles from './style.module.sass'

import NavToolbar from 'components/NavToolbar'
import { setWrapper } from '-core/core-reducer'
import Image from 'components/Image'
import SlideDeck from 'components/SlideDeck'

const HowItWorks = () => {
  const dispatch = useDispatch()
  const reduxUser = useSelector((state) => state.core.user)

  const breadcrumbs = [
    {
      title: 'Home',
      url: '/'
    },
    {
      title: 'How It Works'
    }
  ]

  // Runs on load of component
  useEffect(() => {
    function initComponent() {
      try {
        dispatch(setWrapper({ headerEnabled: true, footerEnabled: false }))
      } catch (e) {
        console.error(e)
      }
    }

    initComponent()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <NavToolbar breadcrumbs={breadcrumbs} backTitle='Back to Home' backUrl='/' />
      <SlideDeck>
        <div className={styles.content}>
          <Row>
            <Col xs={24} md={{ span: 18, offset: 3 }}>
              <Image
                className={styles.image}
                src='/images/metability/metability-generic-banner-737x300-beta.png'
                srcDark='/images/metability/metability-generic-banner-737x300-beta.png'
                alt='Metability'
              />
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }}>
              <h2 className={cn('h4', styles.title)}>How Metability Works</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }}>
              <h4 className={cn('h4', styles.subTitle)}>The Secret Formula 🧐</h4>
            </Col>
          </Row>
        </div>
        <div className={styles.content}>
          <Row>
            <Col xs={{ span: 24 }}>
              <p>
                Our vision at Metability is to reward anyone who is willing to level up their skills on topics that can
                positively impact one's life.
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }}>
              <p>Awards are obtained by completing courses and then participating in Events as and when they occur.</p>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }}>
              <p>
                There are different types of Events in Metability...from Lotteries, to Learnathons, to Challenges, and
                more. Each Event will have certain requirements that need to be met, after which crypto, NFTs and other
                award types can be awarded.
              </p>
            </Col>
          </Row>
        </div>
        <div className={styles.content}>
          <Row>
            <Col xs={{ span: 24 }}>
              <p>Courses can exist in many forms, but are primarily broken down into the following:</p>
            </Col>
            <Col xs={{ span: 24 }}>
              <ul className={styles.list1}>
                <li>Mini Courses</li>
                <li>Full Length Courses</li>
                <li>Documentation</li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }}>
              <p>
                Upon completing a course, you'll be awarded Skill Points and Skill Coins. If working through a course
                native to Metability, these will be awarded upon completion of each lesson.
              </p>
            </Col>
          </Row>
        </div>
        <div className={styles.content}>
          <Row>
            <Col xs={{ span: 24 }}>
              <p>
                Skill Points are similar to one's score in a game. It's based on how frequent you learn or revise work
                and how well you perform during Events.
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }}>
              <p>
                Skill Coins are an in-app currency, which is reserved for Metability features planned for the near
                future.
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }}>
              <p>TIP: You're going to want to earn a lot of Skill Coins 😉.</p>
            </Col>
          </Row>
        </div>
        <div className={styles.content}>
          <Row>
            <Col xs={{ span: 24 }}>
              <p>Metability is currently in a Beta Phase, with the first release targeted for end of March 2022.</p>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }}>
              <p>If you haven't yet, be sure to register with Metability and stay tuned for more 😎.</p>
            </Col>
          </Row>
          {!reduxUser.uid ? (
            <Row>
              <Col xs={{ span: 24 }} style={{ textAlign: 'center' }}>
                <Link className={cn('button', styles.button)} to='/login'>
                  <img
                    src='/images/icons/signin-icon-100.png'
                    alt='Register'
                    style={{ width: 30, marginLeft: -15, marginRight: 5 }}
                  />
                  Get Started
                </Link>
              </Col>
            </Row>
          ) : undefined}
        </div>
        <div className={styles.content}>
          <Row>
            <Col xs={{ span: 24 }}>
              <p>Metability Social Accounts:</p>
            </Col>
            <Col xs={{ span: 24 }}>
              <ul className={styles.list2}>
                <li>
                  <a href='https://discord.gg/pybQ7puTRH' target='_blank' rel='noreferrer'>
                    Discord
                  </a>
                </li>
                <li>
                  <a href='https://www.youtube.com/channel/UCpp1y0owx8MfNsfFeLVI9nw' target='_blank' rel='noreferrer'>
                    YouTube
                  </a>
                </li>
                <li>
                  <a href='https://twitter.com/MetabilityApp' target='_blank' rel='noreferrer'>
                    Twitter
                  </a>
                </li>
                <li>
                  <a href='https://www.facebook.com/Metability-106468505272582' target='_blank' rel='noreferrer'>
                    Facebook
                  </a>
                </li>
                <li>
                  <a href='https://www.instagram.com/metabilityapp' target='_blank' rel='noreferrer'>
                    Instagram
                  </a>
                </li>
                <li>
                  <a href='https://www.tiktok.com/@metabilityapp' target='_blank' rel='noreferrer'>
                    TikTok
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </SlideDeck>
    </>
  )
}

export default HowItWorks
