import React from 'react'
import cn from 'classnames'
import styles from './style.module.sass'

const ClaimModalContent = ({ content, onClose }) => {
  return (
    <div>
      <div>
        <h2 className={cn('h4', styles.title)}>Congratulations 👏</h2>
      </div>
      <div className={cn(styles.content)}>{content}</div>
      <div className={styles.button}>
        <button className={cn('button-stroke')} onClick={onClose}>
          Ok
        </button>
      </div>
    </div>
  )
}

export default ClaimModalContent
