import React, { useEffect, useReducer } from 'react'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Col, Row, List, Avatar } from 'antd'
import MarkdownPreview from '@uiw/react-markdown-preview'
import ReactPlayer from 'react-player'

import styles from './style.module.sass'

import NavToolbar from 'components/NavToolbar'
import Modal from 'components/Modal'
import ClaimModalContent from './ClaimModalContent'
import EventAwardsModalContent from './EventAwardsModalContent'
import { setUserData, setWrapper } from '-core/core-reducer'
import Icon from 'lib/icons-svg'
import { updateUserData } from 'lib/firebase'
import { fetchEventNFTsFromBlockchain, processEventRewards } from 'lib/utils'
import { useMoralisWeb3Api } from 'react-moralis'
import LoadingIndicator from 'components/LoadingIndicator'

const EventsOverview = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const Web3Api = useMoralisWeb3Api()

  const reduxEvents = useSelector((state) => state.core.events.data)
  const reduxUser = useSelector((state) => state.core.user)
  const isMetaMask = useSelector((state) => state.core.web3.isMetaMask)
  const isWeb3Enabled = useSelector((state) => state.core.web3.isWeb3Enabled)

  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    data: {},
    initialized: false,
    requirementsPassed: false,
    eventComplete: false,
    receivedRewards: false,
    modalVisibleClaim: false,
    modalVisibleAwards: false,
    awards: []
  })

  const modalContent =
    'Your rewards are being processed. You will receive an email once they are ready to be claimed 🙂'

  const breadcrumbs = [
    {
      title: 'Home',
      url: '/'
    },
    {
      title: 'Events Overview'
    }
  ]

  // Runs on load of component
  useEffect(() => {
    async function initComponent() {
      let data = null
      let passedRequirement = null
      let requirementsPassed = true
      let userEvents = null
      let eventComplete = false
      let receivedRewards = false
      let nfts = null
      let result = null
      let modalVisibleAwards = false
      let awards = []

      try {
        dispatch(setWrapper({ headerEnabled: true, footerEnabled: true }))

        // Fetch Event Data
        data = reduxEvents.find((item) => item.id === params.eventId)

        // Check if Event has been completed
        if (reduxUser.uid) {
          userEvents = reduxUser.data.state.general.events

          if (userEvents) {
            for (const event of userEvents) {
              if (event.id === params.eventId) {
                if (event.isCompleted) {
                  eventComplete = true
                  if (event.receivedRewards) receivedRewards = true
                  break
                }
              }
            }
          }
        }

        data = JSON.parse(JSON.stringify(data))

        if (eventComplete) {
          for (const requirement of data.requirements) {
            requirement.icon = 'check'
            requirement.iconColor = '#45B26B'
          }
        } else {
          // Work through Requirements to see which ones have been completed
          for (const requirement of data.requirements) {
            passedRequirement = processEventRequirement(requirement)
            if (!passedRequirement) requirementsPassed = false
          }
        }

        // If Event's Complete but no rewards as yet, we need to query and check if rewards have been processed
        if (eventComplete && !receivedRewards && isMetaMask && isWeb3Enabled) {
          nfts = await fetchEventNFTsFromBlockchain(
            Web3Api,
            data.provider,
            data.nftData.chainId,
            data.nftData.contractAddress
          )

          result = await processEventRewards(nfts, reduxUser.uid)

          // If User Data returned, update Redux Store
          if (result.userData) dispatch(setUserData(result.userData))

          // If Awards returned, show dialog congratulating user
          if (result.awards.length > 0) {
            eventComplete = true
            receivedRewards = true
            requirementsPassed = true
            modalVisibleAwards = true
            awards = result.awards
          }
        }

        setState({
          initialized: true,
          data,
          requirementsPassed,
          eventComplete,
          receivedRewards,
          modalVisibleAwards,
          awards
        })
      } catch (e) {
        console.error(e)
      }
    }

    initComponent()
    // eslint-disable-next-line
  }, [])

  const processEventRequirement = (requirement) => {
    let tmpData = null
    let tmpDoc = null
    let isFound = false
    let passedRequirement = false

    try {
      if (state.eventComplete && state.receivedRewards) {
        passedRequirement = true
        requirement.icon = 'check'
        requirement.iconColor = '#45B26B'
        return passedRequirement
      }

      requirement.icon = 'close'
      requirement.iconColor = '#D32F2F'

      switch (requirement.reqType) {
        case 'metability-account':
          if (reduxUser.uid) {
            passedRequirement = true
            requirement.icon = 'check'
            requirement.iconColor = '#45B26B'
          }

          break
        case 'complete-course':
          if (reduxUser.uid) {
            tmpData = reduxUser.data.state.courses
            tmpDoc = tmpData[requirement.params.courseId]

            if (tmpDoc && tmpDoc.status === 'Complete') {
              passedRequirement = true
              requirement.icon = 'check'
              requirement.iconColor = '#45B26B'
            }
          }

          break
        case 'connect-metamask':
          if (reduxUser.uid) {
            tmpData = reduxUser.data.state.general.ethAddress

            if (tmpData && isMetaMask && isWeb3Enabled) {
              passedRequirement = true
              requirement.icon = 'check'
              requirement.iconColor = '#45B26B'
            }
          }

          break
        case 'nft-award':
          if (reduxUser.uid) {
            tmpData = reduxUser.data.state.general.nftAwards

            if (tmpData) {
              for (const award of tmpData) {
                isFound = false

                for (const awardDetail of award.awardDetail) {
                  if (awardDetail.awardType === 'nft') {
                    if (awardDetail.nftContractAddress === requirement.params.contractAddress) {
                      if (awardDetail.nftKey === requirement.params.nftKey) {
                        passedRequirement = true
                        isFound = true
                        requirement.icon = 'check'
                        requirement.iconColor = '#45B26B'
                        break
                      }
                    }
                  }
                }
              }

              if (isFound) break
            }
          }

          break
      }

      return passedRequirement
    } catch (e) {
      console.error(e)
    }
  }

  const handleClaim = async () => {
    let userData = null
    let userEvents = null
    let eventFound = false

    try {
      userData = JSON.parse(JSON.stringify(reduxUser.data))
      userEvents = userData.state.general.events

      if (!userEvents) {
        userData.state.general.events = []
        userData.state.general.eventIds = []
        userEvents = userData.state.general.events
      }

      for (const event of userEvents) {
        if (event.id === params.eventId) {
          eventFound = true
          event.isCompleted = true
          break
        }
      }

      if (!eventFound) {
        userEvents.push({
          id: params.eventId,
          isCompleted: true,
          receivedRewards: false
        })

        userData.state.general.eventIds.push(params.eventId)
      }

      await updateUserData(reduxUser.uid, userData)
      setState({ modalVisibleClaim: true, eventComplete: true })
    } catch (e) {
      console.error(e.message)
    }
  }

  const handleCloseModalClaim = () => {
    setState({ modalVisibleClaim: false })
  }

  const handleCloseModalAwards = () => {
    setState({ modalVisibleAwards: false })
  }

  const handleGoToDashboard = () => {
    try {
      setState({ modalVisibleAwards: false })
      history.replace('/profile')
    } catch (e) {
      console.error(e)
    }
  }

  const handleButtonRequirement = (requirement) => {
    try {
      switch (requirement.reqType) {
        case 'metability-account':
          history.replace('/login')
          break
        case 'complete-course':
          history.replace(`/courses/${requirement.params.courseId}/overview`)
          break
        case 'connect-metamask':
          history.replace('/profile')
          break
      }
    } catch (e) {
      console.error(e.message)
    }
  }

  return (
    <>
      <NavToolbar breadcrumbs={breadcrumbs} backTitle='Back to Home' backUrl='/' />
      {state.initialized ? (
        <Row>
          <Col xs={24} className={cn('section-bg', styles.content)}>
            <Row>
              <Col xs={24} lg={{ span: 23, offset: 1 }} className={styles.titleWrapper}>
                {`EVENT: ${state.data.title}`}
              </Col>
              <Col xs={24} lg={9} xl={7}>
                <center>
                  <div className={styles.imageWrapper}>
                    <img src={state.data.logoUrl} alt={state.data.title} />
                  </div>
                </center>
              </Col>
              <Col xs={24} lg={15} xl={17} className={styles.detailWrapper}>
                <Row>
                  <Col xs={24}>
                    <div className={styles.descriptionWrapper}>{state.data.description}</div>
                  </Col>
                  <Col xs={{ span: 16, offset: 4 }} md={{ span: 8, offset: 8 }} lg={{ span: 24, offset: 0 }}>
                    <div className={styles.icon}>
                      <img src={state.data.provider.logoUrl} alt={state.data.provider.name} />
                    </div>
                    <div className={styles.labels}>
                      Sponsor: <span>{state.data.provider.name}</span>
                    </div>
                  </Col>
                  {state.eventComplete && state.receivedRewards ? (
                    <Col xs={24}>
                      <center>
                        <div className={styles.imageCompleteWrapper}>
                          <img src='/images/icons/event-completed-icon-400.png' alt='Event Completed' />
                        </div>
                      </center>
                    </Col>
                  ) : undefined}
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <div className={styles.sectionTitle}>Event Details</div>
              </Col>
              <Col xs={{ span: 22, offset: 1 }} md={{ span: 24, offset: 0 }} lg={{ span: 22, offset: 1 }}>
                <MarkdownPreview source={state.data.body} className={styles.markdownContent} />
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <div className={styles.sectionTitle}>Event Rewards</div>
              </Col>
              <Col xs={24} lg={{ span: 23, offset: 1 }}>
                <List
                  dataSource={state.data.awards}
                  renderItem={(item) => (
                    <List.Item className={styles.listItem}>
                      {item.awardType === 'nft' ? (
                        <List.Item.Meta
                          avatar={<Avatar src={'/images/icons/nft-icon-100.png'} />}
                          title={<div className={styles.listItemTitle}>{item.label}</div>}
                        ></List.Item.Meta>
                      ) : undefined}
                      {item.awardType === 'skill-points' ? (
                        <List.Item.Meta
                          avatar={<Avatar src={'/images/icons/skill-points-icon-100.png'} />}
                          title={<div className={styles.listItemTitle}>{item.label}</div>}
                        ></List.Item.Meta>
                      ) : undefined}
                      {item.awardType === 'skill-coins' ? (
                        <List.Item.Meta
                          avatar={<Avatar src={'/images/icons/skill-coins-icon-100.png'} />}
                          title={<div className={styles.listItemTitle}>{item.label}</div>}
                        ></List.Item.Meta>
                      ) : undefined}
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <div className={styles.sectionTitle}>Earn This NFT Today!</div>
              </Col>
              <Col xs={24} className={styles.playerWrapper}>
                <ReactPlayer
                  url={state.data.animationUrl}
                  width='100%'
                  height='100%'
                  playing={true}
                  loop={true}
                  muted={true}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <div className={styles.sectionTitle}>Event Requirements</div>
              </Col>
              <Col xs={24} lg={{ span: 23, offset: 1 }}>
                <List
                  dataSource={state.data.requirements}
                  renderItem={(item) => (
                    <List.Item className={styles.listItem}>
                      <List.Item.Meta
                        avatar={<Icon name={item.icon} size='16' fill={item.iconColor} />}
                        title={
                          <div className={styles.listItemWrapper}>
                            <div className={styles.listItemTitle}>{item.label}</div>
                            {item.icon === 'close' && item.reqType !== 'nft-award' ? (
                              <button
                                className={cn('button-small', styles.btnRequirement)}
                                onClick={() => handleButtonRequirement(item)}
                              >
                                <Icon name='report' size='12' />
                                <span>Go</span>
                              </button>
                            ) : undefined}
                          </div>
                        }
                      ></List.Item.Meta>
                    </List.Item>
                  )}
                />
              </Col>

              <Col xs={24} className={styles.btnWrapper}>
                <center>
                  {!state.eventComplete && !state.requirementsPassed ? (
                    <button className={cn('button')} onClick={() => window.location.reload()}>
                      <span>Refresh Requirements</span>
                    </button>
                  ) : undefined}
                  {!state.eventComplete && state.requirementsPassed ? (
                    <button className={cn('button', styles.btnClaim)} onClick={handleClaim}>
                      <span>Claim Rewards</span>
                    </button>
                  ) : undefined}
                  {state.eventComplete && !state.receivedRewards ? (
                    <button className={cn('button', styles.btnClaim)} onClick={() => window.location.reload()}>
                      <span>Check For Rewards</span>
                    </button>
                  ) : undefined}
                </center>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <div className={styles.sectionTitle}>Important Notes</div>
              </Col>
              <Col xs={{ span: 22, offset: 1 }} md={{ span: 24, offset: 0 }} lg={{ span: 22, offset: 1 }}>
                <MarkdownPreview source={state.data.notes} className={styles.markdownContent} />
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <LoadingIndicator />
      )}
      <Modal visible={state.modalVisibleClaim} softClose={false} onClose={handleCloseModalClaim}>
        <ClaimModalContent content={modalContent} onClose={handleCloseModalClaim} />
      </Modal>
      <Modal
        outerClassName={styles.modalOuter}
        visible={state.modalVisibleAwards}
        softClose={false}
        onClose={handleCloseModalAwards}
      >
        <EventAwardsModalContent
          outerClassName={styles.modalOuter}
          awards={state.awards}
          onClose={handleCloseModalAwards}
          onGoToDashboard={handleGoToDashboard}
        />
      </Modal>
    </>
  )
}

export default EventsOverview
