import React, { useEffect, useReducer } from 'react'
import cn from 'classnames'
import { Row, Col, Divider, message } from 'antd'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import orderBy from 'lodash/orderBy'
// import { useMoralis } from 'react-moralis'
import LoadingIndicator from 'components/LoadingIndicator'

import styles from './style.module.sass'

import Icon from 'lib/icons-svg'
import { formatNumber, abbreviateWalletAddress } from 'lib/utils'
import CopyToClipboard from 'react-copy-to-clipboard'
// import ButtonSpinner from 'components/ButtonSpinner'

const VIDEO_EXTENSIONS = ['.mp4', 'webm', '3gp', '.ogg', '.ogv', '.oga', '.ogx', '.ogm', '.spx', '.opu']

const User = () => {
  // const { enableWeb3 } = useMoralis()

  const reduxUser = useSelector((state) => state.core.user)
  const reduxAwards = useSelector((state) => state.core.user.data?.state?.general?.nftAwards)
  const isMetaMask = useSelector((state) => state.core.web3.isMetaMask)
  const isWeb3Enabled = useSelector((state) => state.core.web3.isWeb3Enabled)

  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    skillPoints: 0,
    skillCoins: 0,
    skillLevel: 0,
    achievements: [],
    disableForm: false,
    addressAbbr: ''
  })

  useEffect(() => {
    async function init() {
      let addressAbbr = null
      let achievements = []
      let skillPoints = 0
      let skillCoins = 0
      let skillLevel = 0
      let nftType = null
      let url = null

      try {
        if (reduxUser.uid) {
          skillPoints = formatNumber(reduxUser.data.state.general.skillPoints)
          skillCoins = formatNumber(reduxUser.data.state.general.skillCoins)
          skillLevel = reduxUser.data.state.general.skillLevel

          addressAbbr = reduxUser.data.state.general.ethAddress
          if (addressAbbr) addressAbbr = await abbreviateWalletAddress(addressAbbr)

          // Check for NFT Achievements
          for (const award of reduxAwards) {
            for (const awardDetail of award.awardDetail) {
              if (awardDetail.awardType === 'nft') {
                url = awardDetail.nftImage.toLowerCase()
                nftType = 'image'

                for (const ext of VIDEO_EXTENSIONS) {
                  if (url.indexOf(ext) > -1) nftType = 'video'
                  break
                }

                achievements.push({
                  nftType,
                  title: award.courseTitle,
                  provider: award.provider,
                  providerLogo: award.providerLogo,
                  nftImage: awardDetail.nftImage,
                  timestamp: award.timestamp
                })
              }
            }
          }

          achievements = orderBy(achievements, 'timestamp', 'desc')
          setState({ skillPoints, skillCoins, skillLevel, achievements, addressAbbr })
        }
      } catch (e) {
        console.error(e)
      }
    }

    init()
    // eslint-disable-next-line
  }, [reduxUser.uid, reduxAwards])

  // const handleConnectMetaMask = async () => {
  //   try {
  //     setState({ disableForm: true })
  //     await enableWeb3()
  //     setState({ disableForm: false })
  //   } catch (e) {
  //     setState({ disableForm: false })
  //     console.error(e)
  //   }
  // }

  return (
    <>
      {!reduxUser.uid ? (
        <LoadingIndicator msg={state.loadingMessage} />
      ) : (
        <Row>
          <Col xs={24}>
            <div className={cn(styles.user)}>
              <Row>
                <Col xs={24} md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 4 }} xl={{ span: 6, offset: 6 }}>
                  <div className={styles.avatar}>
                    <img
                      src={
                        reduxUser.data?.profilePicId
                          ? `https://apidemo.agilite.io/files/${reduxUser.data.profilePicId}`
                          : '/images/icons/metability-profile-default-icon-100.jpg'
                      }
                      alt='Avatar'
                    />
                  </div>
                </Col>
                <Col xs={24} md={12} lg={8} xl={6}>
                  <Row className={styles.wrapper}>
                    <Col xs={24}>
                      <div className={styles.name}>{reduxUser.data?.username.toUpperCase() || 'ANONYMOUS'}</div>
                    </Col>
                    {isMetaMask && isWeb3Enabled && reduxUser.data?.state.general.ethAddress ? (
                      <Col xs={24}>
                        <div className={styles.code}>
                          <div className={styles.number}>{state.addressAbbr}</div>
                          <CopyToClipboard
                            text={reduxUser.data.state.general.ethAddress}
                            onCopy={() => message.success('Eth address copied to clipboard')}
                          >
                            <button className={styles.copy}>
                              <Icon name='copy' size='16' />
                            </button>
                          </CopyToClipboard>
                        </div>
                      </Col>
                    ) : undefined}

                    <Col xs={24} md={12} lg={14}>
                      <img className={styles.imgIcon} src='/images/icons/skill-level-icon-100.png' alt='Skill Level' />
                      <span className={styles.labelValue}>Skill Level</span>
                    </Col>
                    <Col xs={24} md={12} lg={10}>
                      <div className={styles.labelValue}>{state.skillLevel}</div>
                    </Col>
                    <Col xs={24} md={12} lg={14}>
                      <img
                        className={styles.imgIcon}
                        src='/images/icons/skill-points-icon-100.png'
                        alt='Skill Points'
                      />
                      <span className={styles.labelValue}>Skill Points</span>
                    </Col>
                    <Col xs={24} md={12} lg={10} style={{ marginTop: 1 }}>
                      <div className={styles.labelValue}>{state.skillPoints}</div>
                    </Col>
                    <Col xs={24} md={12} lg={14}>
                      <img className={styles.imgIcon} src='/images/icons/skill-coins-icon-100.png' alt='Skill Coins' />
                      <span className={styles.labelValue}>Skill Coins</span>
                    </Col>
                    <Col xs={24} md={12} lg={10} style={{ marginTop: 1 }}>
                      <div className={styles.labelValue}>{state.skillCoins}</div>
                    </Col>
                    {isMetaMask && !isWeb3Enabled ? (
                      <>
                        <Col xs={24} className={styles.metaMaskWrapper}>
                          <span className={styles.labelValue}>
                            You seem to have MetaMask installed, but it's not currently initialized. Check if it's
                            currently locked and refresh this Page to try again.
                          </span>
                        </Col>
                        <Col xs={24} className={styles.metaMaskWrapper}>
                          <button className={cn('button', styles.btnRefresh)} onClick={() => window.location.reload()}>
                            <span>Refresh Page</span>
                            <Icon name='share' size='16' />
                          </button>
                        </Col>
                      </>
                    ) : undefined}
                    <Col xs={24} className={styles.btns}>
                      <Link className={cn('button', styles.button)} to='profile-edit'>
                        <span>Edit Profile</span>
                        <Icon name='image' size='16' />
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  <Divider className={styles.divider}>Achievements</Divider>
                </Col>
              </Row>
              <Row>
                {state.achievements.map((item, index) => (
                  <Col key={index} xs={24} md={12} lg={8} xl={6} className={styles.achievementWrapper}>
                    <div className={styles.achievement}>
                      <div className={styles.title}>{item.title}</div>
                      {item.nftType === 'image' ? (
                        <img src={item.nftImage} alt={item.courseTitle} />
                      ) : (
                        <video src={item.nftImage} autoPlay={true} loop={true} />
                      )}
                    </div>
                  </Col>
                ))}
              </Row>
              {/* {isMetaMask && !ethAddress ? (
              <Row justify='center'>
                <Col>
                  <div className={styles.btns}>
                    <button
                      className={cn('button', styles.button, state.disableForm ? styles.buttonDisabled : undefined)}
                      onClick={handleConnectMetaMask}
                      disabled={state.disableForm}
                    >
                      <ButtonSpinner spinning={state.disableForm} />
                      <img
                        src='/images/metamask-fox.svg'
                        alt='Sign in using MetaMask'
                        style={{ width: 30, marginRight: 5 }}
                      />
                      Link MetaMask
                    </button>
                  </div>
                </Col>
              </Row>
            ) : undefined} */}
            </div>
          </Col>
        </Row>
      )}
    </>
  )
}

export default User
