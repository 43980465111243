import React from 'react'
import cn from 'classnames'
import Slider from 'react-slick'
import styles from './style.module.sass'
import Icon from 'lib/icons-svg'

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => <button {...props}>{children}</button>

const SlideDeckCourses = ({ data, title, onCourseClick }) => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: (
      <SlickArrow>
        <Icon name='arrow-next' size='14' />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name='arrow-prev' size='14' />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 1340,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          infinite: true
        }
      }
    ]
  }

  return (
    <>
      <div className={cn('section-bg', styles.section)}>
        <div className={cn('container')}>
          <div className={styles.wrapper}>
            <div className={styles.stage}>{title}</div>
            <Slider className='slide-deck-courses' {...settings}>
              {data.map((item, index) => (
                <div key={index} className={styles.item} onClick={() => onCourseClick(item.id)}>
                  <div className={styles.body}>
                    <div className={styles.imgWrapper}>
                      {item.image && item.image.data ? (
                        <img src={`data:image/png;base64,${item.image.data}`} alt={item.title} />
                      ) : undefined}
                      {item.image && !item.image.data ? <img src={item.image} alt={item.title} /> : undefined}
                    </div>
                    <div className={styles.title}>{item.title}</div>
                  </div>
                  <div className={styles.head}>
                    <img className={styles.icon} src={item.provider.logoUrl} alt={item.provider.name} />
                    <div className={styles.source}>{item.provider.name.toUpperCase()}</div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  )
}

export default SlideDeckCourses
