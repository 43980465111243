import React from 'react'
import cn from 'classnames'
import styles from './styles.module.sass'
import Icon from 'lib/icons-svg'

const Add = ({ item, onAddRemoveCard }) => {
  const handleAddRemoveCard = () => {
    try {
      if (item.isCardAdded) {
        onAddRemoveCard(item, 'remove')
      } else {
        onAddRemoveCard(item, 'add')
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <button
      className={cn(styles.add, {
        [styles.active]: item.isCardAdded
      })}
      onClick={handleAddRemoveCard}
    >
      <Icon name='add-square' size='24' />
      <Icon name='minus-square' size='24' />
    </button>
  )
}

export default Add
