import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth'
import cn from 'classnames'

import styles from './style.module.sass'

import Image from 'components/Image'
import { Col, Row } from 'antd'

const Anonymous = () => {
  const history = useHistory()

  // Check User State if Logged In via cached session
  useEffect(() => {
    async function init() {
      try {
        // Confirm the link is a sign-in with email link.
        const auth = getAuth()

        if (isSignInWithEmailLink(auth, window.location.href)) {
          let email = window.localStorage.getItem('emailForSignIn')

          if (!email) {
            email = window.prompt('Please provide your email for confirmation')
          }

          await signInWithEmailLink(auth, email, window.location.href)
          window.localStorage.removeItem('emailForSignIn')
          history.replace('/')
        }
      } catch (e) {
        console.error(e)
      }
    }

    init()

    // eslint-disable-next-line
  }, [])

  return (
    <div className={styles.wrapper}>
      <Row>
        <Col
          xs={24}
          md={{ span: 16, offset: 4 }}
          lg={{ span: 12, offset: 6 }}
          xl={{ span: 8, offset: 8 }}
          className={styles.logoWrapper}
        >
          <Image
            srcSet='/images/metability/metability-generic-banner-737x300-beta.png'
            srcSetDark='/images/metability/metability-generic-banner-737x300-beta.png'
            src='/images/metability/metability-generic-banner-737x300-beta.png'
            srcDark='/images/metability/metability-generic-banner-737x300-beta.png'
            alt='Metability'
          />
        </Col>
        <Col xs={24} lg={{ span: 12, offset: 6 }} xl={{ span: 8, offset: 8 }}>
          <Row>
            <Col xs={24} className={styles.titleWrapper}>
              <h1 className={cn('h1')}>Learn-2-Earn</h1>
            </Col>
            <Col xs={{ span: 18, offset: 3 }} md={{ span: 9, offset: 3 }} className={styles.btnWrapper}>
              <Link className={cn('button', styles.button)} to='/login'>
                <img src='/images/icons/signin-icon-100.png' alt='Sign In' />
                Sign In
              </Link>
            </Col>
            <Col xs={{ span: 18, offset: 3 }} md={{ span: 9, offset: 1 }} className={styles.btnWrapper}>
              <Link className={cn('button-stroke', styles.button)} to='/register'>
                Get Started
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default Anonymous
