import React, { useEffect, useReducer, useRef } from 'react'
import { Row, Col, Tag } from 'antd'
import ReactPlayer from 'react-player/lazy'
import cn from 'classnames'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import MarkdownPreview from '@uiw/react-markdown-preview'

import styles from './style.module.sass'

import Modal from '../Modal'
import ButtonSpinner from 'components/ButtonSpinner'
import ClaimLessonModalContent from '../ClaimLessonModalContent'

import { setUser } from '-core/core-reducer'
import { getUserCourseTemplate, getUserLessonTemplate, getUserLevelTemplate } from 'lib/templates'
import { updateUserData } from 'lib/firebase'
import { AudioOutlined, FileMarkdownOutlined, VideoCameraOutlined } from '@ant-design/icons'

const LessonContent = ({ course, lessonContent, lessonId, levelId }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const textWrapperRef = useRef()
  const reduxUser = useSelector((state) => state.core.user)

  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    level: null,
    lesson: null,
    userCourse: null,
    modalVisible: false,
    canCloseModal: false,
    lessonsCompleted: false,
    hasQuiz: false,
    skillPoints: 0,
    skillCoins: 0,
    claimed: false,
    loading: false,
    slide: 1,
    nextLessonId: '',
    slideDeckKey: Date.now(),
    lessonTag: '',
    activeTab: '',
    hasViewedContent: false
  })

  // Manage state of lesson on load
  useEffect(() => {
    function init() {
      let userCourse = null
      let userLevel = null
      let userLesson = null
      let claimed = false
      let level = null
      let lesson = null
      let nextLessonId = ''
      let lessonTag = ''
      let activeTab = ''

      try {
        // Get Course Level & Lesson
        level = course.levels.find((item) => item.id === levelId)
        lesson = level.lessons.find((item) => item.id === lessonId)

        // Get User Course
        userCourse = reduxUser.data.state.courses[course.id]

        if (userCourse) {
          userLevel = userCourse.levels[levelId]

          if (userLevel) {
            userLesson = userLevel.lessons[lesson.id]
            if (userLesson) claimed = true
          }
        }

        if (lesson.sortOrder < level.lessons.length) {
          nextLessonId = level.lessons[lesson.sortOrder].id
        }

        lessonTag = `lesson ${lesson.sortOrder} (lvl ${level.sortOrder})`

        // Set default Active Tab
        if (lessonContent.videoUrl) {
          activeTab = 'video'
        } else if (lessonContent.audioUrl) {
          activeTab = 'audio'
        } else if (lessonContent.content) {
          activeTab = 'text'
        }

        setState({
          claimed,
          hasViewedContent: false,
          level,
          lesson,
          userCourse,
          nextLessonId,
          lessonTag,
          activeTab
        })

        // This is for the Markdown Content
        window.scrollTo(0, 0)
      } catch (e) {
        console.error(e)
      }
    }

    init()
    // eslint-disable-next-line
  }, [lessonId])

  const handleQuiz = () => {
    setState({ modalVisible: false })
    history.replace(`/courses/${course.id}/levels/${levelId}/quiz`)
  }

  const handleClaim = async () => {
    let lessons = null
    let userCourse = null
    let userLevel = null
    let errMsg = null
    let tmpUser = null
    let skillPoints = 0
    let skillCoins = 0
    let nextLessonId = ''
    let canCloseModal = true
    let lessonsCompleted = true

    try {
      // Disable Claim Button
      setState({ hasViewedContent: false, loading: true })

      // Update User State by adding lesson and incrementing points
      if (!state.lesson.skillPoints || !state.lesson.skillCoins) {
        errMsg = `No Skill Points or Coins found for Lesson: ${lessonId}`
        throw new Error(errMsg)
      }

      tmpUser = JSON.parse(JSON.stringify(reduxUser))
      skillPoints = parseInt(state.lesson.skillPoints)
      skillCoins = parseInt(state.lesson.skillCoins)

      tmpUser.data.state.general.skillPoints += skillPoints
      tmpUser.data.state.general.skillCoins += skillCoins

      // First User Course
      if (!state.userCourse) {
        tmpUser.data.state.courses[course.id] = getUserCourseTemplate()
      } else {
        tmpUser.data.state.courses[course.id] = JSON.parse(JSON.stringify(state.userCourse))
      }

      userCourse = tmpUser.data.state.courses[course.id]
      userCourse.skillPoints += skillPoints
      userCourse.skillCoins += skillCoins

      // Then User Level
      userLevel = userCourse.levels[levelId]

      if (!userLevel) {
        userCourse.levels[levelId] = getUserLevelTemplate()
        userLevel = userCourse.levels[levelId]
      }

      userLevel.skillPoints += skillPoints
      userLevel.skillCoins += skillCoins

      // Finally, add the lesson
      // There can't be a lesson, as we only claim once
      userLevel.lessons[lessonId] = getUserLessonTemplate()
      userLevel.lessons[lessonId].skillPoints = skillPoints
      userLevel.lessons[lessonId].skillCoins = skillCoins

      // Check if all lessons for Level are completed
      lessons = course.levels.find((item) => item.id === levelId).lessons
      lessonsCompleted = true

      if (!userLevel.quizComplete) {
        for (const tmpLesson of lessons) {
          if (!userLevel.lessons[tmpLesson.id]) {
            lessonsCompleted = false
            break
          }
        }
      }

      userLevel.lessonsCompleted = lessonsCompleted

      await updateUserData(tmpUser.uid, tmpUser.data)
      dispatch(setUser(tmpUser))

      // Update Component State
      if (state.lesson.sortOrder < lessons.length) {
        nextLessonId = lessons[state.lesson.sortOrder].id
      }

      setState({
        modalVisible: true,
        hasViewedContent: true,
        loading: false,
        nextLessonId,
        canCloseModal,
        skillPoints,
        skillCoins,
        claimed: true,
        hasQuiz: !userLevel.quizComplete,
        lessonsCompleted: userLevel.lessonsCompleted
      })
    } catch (e) {
      console.error(e)
    }
  }

  const handleOnBtnClickNext = (e) => {
    try {
      setState({ modalVisible: false, slideDeckKey: Date.now() })
      history.replace(`/courses/${course.id}/levels/${levelId}/lessons/${state.nextLessonId}`)
    } catch (e) {
      console.error(e)
    }
  }

  const handleModalClose = () => {
    try {
      if (state.canCloseModal) setState({ modalVisible: false })
    } catch (e) {
      console.error(e)
    }
  }

  const handleTextScroll = () => {
    if (state.hasViewedContent) return

    if (textWrapperRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = textWrapperRef.current

      if (Math.floor(scrollTop + clientHeight) >= Math.floor(scrollHeight)) {
        setState({ hasViewedContent: true })
      }
    }
  }

  return (
    <div>
      <div className={cn('container', styles.container)}>
        {state.lesson ? (
          <>
            <Row>
              <Col xs={24} md={{ span: 22, offset: 1 }}>
                <div className={styles.header}>
                  <Row>
                    <Col xs={4}>
                      {state.lessonTag ? (
                        <Tag color='purple' className={styles.tag}>
                          {state.lessonTag}
                        </Tag>
                      ) : undefined}
                    </Col>
                    <Col xs={16}>
                      <h1 className={styles.title}>{state.lesson.title}</h1>
                    </Col>
                    <Col xs={4} className={styles.tagEnd}>
                      {state.claimed ? (
                        <Tag color='orange' className={styles.tag}>
                          claimed
                        </Tag>
                      ) : undefined}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={{ span: 22, offset: 1 }}>
                {state.activeTab === 'video' ? (
                  <div className={styles.playerWrapper}>
                    <ReactPlayer
                      onEnded={() => setState({ hasViewedContent: true })}
                      url={lessonContent.videoUrl}
                      width='100%'
                      height='100%'
                      controls={true}
                    />
                  </div>
                ) : undefined}
                {state.activeTab === 'text' ? (
                  <div className={styles.textWrapper} onScroll={handleTextScroll} ref={textWrapperRef}>
                    <MarkdownPreview source={lessonContent.content} className={styles.markdown} />
                  </div>
                ) : undefined}
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={{ span: 22, offset: 1 }}>
                <div className={styles.footer}>
                  <Row>
                    <Col xs={16} className={styles.footerBtnWrapper}>
                      {lessonContent.videoUrl ? (
                        <button
                          className={cn(
                            'button-xs',
                            state.activeTab !== 'video' ? 'button-stroke' : undefined,
                            styles.btnTab
                          )}
                          onClick={handleClaim}
                        >
                          <VideoCameraOutlined className={styles.antIcon} />
                          Video
                        </button>
                      ) : undefined}

                      {lessonContent.audioUrl ? (
                        <button
                          className={cn(
                            'button-xs',
                            state.activeTab !== 'audio' ? 'button-stroke' : undefined,
                            styles.btnTab
                          )}
                          onClick={handleClaim}
                        >
                          <AudioOutlined className={styles.antIcon} />
                          Audio
                        </button>
                      ) : undefined}
                      {lessonContent.content ? (
                        <button
                          className={cn(
                            'button-xs',
                            state.activeTab !== 'text' ? 'button-stroke' : undefined,
                            styles.btnTab
                          )}
                          onClick={handleClaim}
                        >
                          <FileMarkdownOutlined className={styles.antIcon} />
                          Text
                        </button>
                      ) : undefined}
                    </Col>
                    <Col xs={8} className={styles.footerBtnWrapper} style={{ textAlign: 'end' }}>
                      {!state.claimed ? (
                        <button
                          className={cn(
                            'button-xs',
                            !state.hasViewedContent ? styles.buttonDisabled : styles.buttonClaim
                          )}
                          onClick={handleClaim}
                          disabled={!state.hasViewedContent}
                        >
                          <ButtonSpinner spinning={state.loading} />
                          Claim
                        </button>
                      ) : (
                        <button
                          className={cn('button-xs', styles.btnNextLesson)}
                          onClick={handleOnBtnClickNext}
                          hidden={!state.nextLessonId}
                        >
                          Next Lesson
                        </button>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </>
        ) : undefined}
      </div>
      <Modal visible={state.modalVisible} softClose={false} onClose={handleModalClose}>
        <ClaimLessonModalContent
          nextLesson={state.nextLesson}
          canCloseModal={state.canCloseModal}
          skillPoints={state.skillPoints}
          skillCoins={state.skillCoins}
          hasQuiz={state.hasQuiz}
          lessonsCompleted={state.lessonsCompleted}
          onBtnClickNext={handleOnBtnClickNext}
          onBtnClickQuiz={handleQuiz}
          onBtnClickClose={handleModalClose}
        />
      </Modal>
    </div>
  )
}

export default LessonContent
