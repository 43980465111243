import React, { useEffect } from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'

import styles from './style.module.sass'
import Icon from 'lib/icons-svg'
import { useDispatch } from 'react-redux'
import { setHeaderBackButton } from '-core/core-reducer'

const NavToolbar = ({ breadcrumbs, backTitle, backUrl }) => {
  const dispatch = useDispatch()

  // Runs every time Nav criteria changes
  useEffect(() => {
    function init() {
      try {
        dispatch(setHeaderBackButton(backUrl))
      } catch (e) {
        console.error(e)
      }
    }

    init()

    return () => {
      dispatch(setHeaderBackButton(''))
    }

    // eslint-disable-next-line
  }, [])

  return (
    <div className={cn(styles.control)}>
      <div className={cn('container', styles.container)}>
        <Link className={cn('button-stroke button-small', styles.button)} to={backUrl}>
          <Icon name='arrow-prev' size='10' />
          <span>{backTitle}</span>
        </Link>
        <div className={styles.breadcrumbs}>
          {breadcrumbs.map((x, index) => (
            <div className={styles.item} key={index}>
              {x.url ? (
                <Link className={styles.link} to={x.url}>
                  {x.title}
                </Link>
              ) : (
                x.title
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default NavToolbar
