import React, { useState } from 'react'
import cn from 'classnames'
import OutsideClickHandler from 'react-outside-click-handler'

import styles from './style.module.sass'
import Icon from 'lib/icons-svg'

const Dropdown = ({ className, value, stateProp, setValue, options }) => {
  const [visible, setVisible] = useState(false)

  const handleClick = (value) => {
    if (stateProp) {
      const tmpObject = {}
      tmpObject[stateProp] = value
      setValue(tmpObject)
    } else {
      setValue(value)
    }

    setVisible(false)
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(styles.dropdown, className, { [styles.active]: visible })}>
        <div className={styles.head} onClick={() => setVisible(!visible)}>
          <div className={styles.selection}>{value}</div>
          <div className={styles.arrow}>
            <Icon name='arrow-bottom' size='10' />
          </div>
        </div>
        <div className={styles.body}>
          {options.map((x, index) => (
            <div
              className={cn(styles.option, {
                [styles.selectioned]: x === value
              })}
              onClick={() => handleClick(x, index)}
              key={index}
            >
              {x}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  )
}

export default Dropdown
