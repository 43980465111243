import React, { useEffect, useReducer } from 'react'
import { withRouter, useLocation, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { clearAllBodyScrollLocks } from 'body-scroll-lock'
import useDarkMode from 'use-dark-mode'
import { onAuthStateChanged } from 'firebase/auth'
import { useMoralis, useChain, useMoralisWeb3Api } from 'react-moralis'
import Axios from 'agilite-utils/axios'
import styles from './style.module.sass'

import { createTransactionLog, logAnalyticsEvent, updateUserData } from 'lib/firebase'
import Header from 'components/Header'
import Footer from 'components/Footer'
import LoadingIndicator from 'components/LoadingIndicator'
import UserAwardsModalContent from 'components/UserAwardsModalContent'
import UserLevelUpModalContent from 'components/UserLevelUpModalContent'
import Modal from 'components/Modal'

import { setUser, setCourseCatalogue, setWeb3Data, setUserData, setEvents } from '-core/core-reducer'
import { getCoreStateTemplate, getLogTemplate, getUserTemplate } from 'lib/templates'
import LoginUser from 'screens/LoginUser'
import { fetchNFTsFromBlockchain, processNFTs, sortCatalogueSections } from 'lib/utils'
import { auth, fetchUserData, addUserData } from 'lib/firebase'

const Wrapper = ({ children }) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const history = useHistory()
  const Web3Api = useMoralisWeb3Api()
  const darkMode = useDarkMode(false)
  const { enableWeb3, isWeb3Enabled, provider } = useMoralis()
  const { chain, account } = useChain()

  const { headerEnabled, footerEnabled } = useSelector((state) => state.core)
  const reduxUser = useSelector((state) => state.core.user)
  const reduxCatalogue = useSelector((state) => state.core.courseCatalogue)

  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    loadingMessage: '',
    userAuthed: false,
    courseProviders: null,
    modalVisibleAward: false,
    modalVisibleLevelUp: false,
    awards: [],
    newLevel: null
  })

  // Operations to run on Start of App
  useEffect(() => {
    async function init() {
      let userObject = null
      let userData = null
      let result = []
      let tmpData = null
      let featured = []
      let recent = []
      let newbies = []
      let nft = []
      let buildspace = []
      let metaschool = []
      let metability = []
      let events = null

      try {
        // If Version changed, clear localstorage
        tmpData = localStorage.getItem('metability_version')

        if (tmpData !== process.env.REACT_APP_VERSION) {
          localStorage.clear()
          localStorage.setItem('metability_version', process.env.REACT_APP_VERSION)
        }

        // Enable Web3
        enableWeb3()

        // Fetch Events
        setState({ loadingMessage: 'Initializing Events' })
        events = await Axios.request({
          method: 'get',
          url: `${process.env.REACT_APP_API_SERVER_URL}/events/data`,
          headers: {
            'api-key': process.env.REACT_APP_AGILITE_API_KEY
          }
        })

        events = events.data
        for (const entry of events.data) {
          // Connect Provider and Creator
          entry.catalogueType = 'event'
          entry.provider = events.providers[entry.providerId]

          if (events.creators[entry.creatorId]) {
            entry.creator = events.creators[entry.creatorId]
          } else {
            entry.creator = entry.provider
          }

          if (entry.custom.catalogueSections.featured.enabled) {
            featured.push(entry)
          }
        }

        dispatch(setEvents(events.data))

        // Fetch Course Catalogue
        setState({ loadingMessage: 'Initializing Courses' })

        result = await Axios.request({
          method: 'get',
          url: `${process.env.REACT_APP_API_SERVER_URL}/courseCatalogue/getExploreCourses`,
          headers: {
            'api-key': process.env.REACT_APP_AGILITE_API_KEY
          }
        })

        result = result.data

        // Prep State
        setState({ loadingMessage: '' })
        for (const entry of result.data) {
          // Connect Provider and Creator Data
          entry.catalogueType = 'course'
          entry.provider = result.providers[entry.providerId]

          if (result.creators[entry.creatorId]) {
            entry.creator = result.creators[entry.creatorId]
          } else {
            entry.creator = entry.provider
          }

          // Determine Price class-name
          switch (entry.price.toLowerCase()) {
            case 'free':
              entry.priceClass = 'price-free'
              break
            default:
              entry.priceClass = 'price-default'
          }

          // Setup Course Catalogue
          if (entry.custom.catalogueSections.featured.enabled) {
            featured.push(entry)
          }

          if (entry.custom.catalogueSections.recent.enabled) {
            recent.push(entry)
          }

          if (entry.custom.catalogueSections.newbies.enabled) {
            newbies.push(entry)
          }

          if (entry.custom.catalogueSections.nft.enabled) {
            nft.push(entry)
          }

          if (entry.custom.catalogueSections.buildspace.enabled) {
            buildspace.push(entry)
          }

          if (entry.custom.catalogueSections.metaschool.enabled) {
            metaschool.push(entry)
          }

          if (entry.custom.catalogueSections.metability.enabled) {
            metability.push(entry)
          }
        }

        // Next, we have to sort the Arrays according to indexes
        // Featured
        featured = await sortCatalogueSections(featured, 'featured')
        recent = await sortCatalogueSections(recent, 'recent')
        newbies = await sortCatalogueSections(newbies, 'newbies')
        nft = await sortCatalogueSections(nft, 'nft')
        buildspace = await sortCatalogueSections(buildspace, 'buildspace')
        metaschool = await sortCatalogueSections(metaschool, 'metaschool')
        metability = await sortCatalogueSections(metability, 'metability')

        tmpData = {
          initialized: true,
          all: result.data,
          featured,
          recent,
          newbies,
          nft,
          buildspace,
          metaschool,
          metability
        }

        dispatch(setCourseCatalogue(tmpData))
        setState({ courseProviders: result.providers })
        // Check if User Auth state has changed or is complete
        onAuthStateChanged(auth, async (user) => {
          if (user) {
            // Check if User State exists
            userData = await fetchUserData(user.uid)

            if (!userData) {
              userData = getUserTemplate()
              if (window.ethereum?.selectedAddress) {
                userData.state.general.ethAddress = window.ethereum?.selectedAddress
              }

              await addUserData(user.uid, userData)
            }

            userObject = { uid: user.uid, authComplete: true, data: userData }
          } else {
            userObject = { uid: '', authComplete: true, data: {} }
          }

          dispatch(setUser(userObject))
        })
      } catch (e) {
        console.error(e)
      }
    }

    init()

    // eslint-disable-next-line
  }, [])

  // Google Analytics Screen Tracking & Transaction Logging
  useEffect(() => {
    const init = async () => {
      try {
        // Submit Log if user is logged in
        if (!reduxUser.uid) return
        const entry = getLogTemplate(reduxUser.uid, darkMode.value)
        entry.path = pathname
        entry.code = 'url_path'
        await createTransactionLog(entry)
        await logAnalyticsEvent(entry.code, entry.path)
      } catch (e) {
        console.error(e)
      }
    }

    init()

    // eslint-disable-next-line
  }, [pathname, reduxUser.authComplete])

  // Manage Routing Access Control
  useEffect(() => {
    // if (state.userAuthed) return
    if (reduxUser.uid) return setState({ userAuthed: true })

    // We need to allow certain pages to be accessed by Anonymous users
    switch (pathname) {
      case '/':
      case '/login':
      case '/register':
      case '/about':
      case '/howitworks':
      case '/gettingstarted':
      case '/faq':
      case '/alphaprogram':
        // Allowed
        setState({ userAuthed: true })
        break
      default:
        // Check if user is accessing events page
        if (pathname.toLowerCase().indexOf('/events/') > -1) {
          // Allowed
          setState({ userAuthed: true })
        } else {
          // Unauthorized
          setState({ userAuthed: false })
        }
    }

    // eslint-disable-next-line
  }, [pathname, reduxUser.uid])

  // Scroll to top of page when path changes
  useEffect(() => {
    window.scrollTo(0, 0)
    clearAllBodyScrollLocks()
  }, [pathname])

  // Update Redux when relevant Web3 Props change
  useEffect(() => {
    async function init() {
      let userData = null

      try {
        const web3Data = getCoreStateTemplate().web3

        if (window.ethereum) {
          web3Data.isMetaMask = window.ethereum.isMetaMask
          web3Data.ethAddress = window.ethereum.selectedAddress
        }

        if (account) web3Data.ethAddress = account
        web3Data.isWeb3Enabled = isWeb3Enabled

        if (chain) {
          web3Data.chain.id = chain.chainId
          web3Data.chain.name = chain.name
          web3Data.chain.shortName = chain.shortName
          web3Data.chain.networkId = chain.networkId
          web3Data.chain.currency = chain.nativeCurrency.name
        }

        dispatch(setWeb3Data(web3Data))

        // Add Ethereum Address if relevant
        if (reduxUser.uid && reduxUser.data && web3Data.ethAddress) {
          if (!reduxUser.data.state.general.ethAddress) {
            userData = JSON.parse(JSON.stringify(reduxUser.data))
            userData.state.general.ethAddress = window.ethereum.selectedAddress
            await updateUserData(reduxUser.uid, userData)
            dispatch(setUserData(userData))
          }
        }
      } catch (e) {
        console.error(e)
      }
    }

    init()

    // eslint-disable-next-line
  }, [isWeb3Enabled, chain, account, reduxUser.uid, reduxUser.data])

  // Check for NFTs, if relevant
  useEffect(() => {
    async function init() {
      let nfts = null
      let result = null
      let modalVisibleAward = false

      try {
        if (isWeb3Enabled && provider?.isMetaMask && account && reduxUser.uid && state.courseProviders) {
          // If we get here, User has logged in and has MetaMask Address
          // First we check to see if they have any NFTs from the Blockchain
          nfts = await fetchNFTsFromBlockchain(Web3Api, state.courseProviders)

          // If yes, we need to check if they've already been awarded for these NFTs
          result = await processNFTs(nfts, reduxUser.uid)

          // If User Data returned, update Redux Store
          if (result.userData) dispatch(setUserData(result.userData))

          // If Awards returned, show dialog congratulating user
          if (result.awards.length > 0) modalVisibleAward = true

          setState({ modalVisibleAward, awards: result.awards, newLevel: result.newLevel })
        }
      } catch (e) {
        console.error(e)
      }
    }

    init()

    // eslint-disable-next-line
  }, [isWeb3Enabled, provider, account, reduxUser.uid, state.courseProviders])

  const handleModalAwardClose = () => {
    try {
      setState({ modalVisibleAward: false })
      checkForLevelUp()
    } catch (e) {
      console.error(e)
    }
  }

  const handleGoToDashboard = () => {
    try {
      setState({ modalVisibleAward: false })
      history.replace('/profile')
      checkForLevelUp()
    } catch (e) {
      console.error(e)
    }
  }

  const handleModalLevelUpClose = () => {
    try {
      setState({ modalVisibleLevelUp: false })
    } catch (e) {
      console.error(e)
    }
  }

  const checkForLevelUp = () => {
    try {
      if (state.newLevel) {
        setState({ modalVisibleLevelUp: true })
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <div className={styles.page}>
        {headerEnabled ? <Header /> : undefined}
        {!reduxCatalogue.initialized || !reduxUser.authComplete ? (
          <LoadingIndicator msg={state.loadingMessage} />
        ) : undefined}
        {reduxCatalogue.initialized && reduxUser.authComplete && !state.userAuthed ? (
          <LoginUser forcedLogin={true} pathName={pathname} />
        ) : undefined}
        {reduxCatalogue.initialized && reduxUser.authComplete && state.userAuthed ? (
          <>
            <div className={styles.inner}>{children}</div>
            {footerEnabled ? <Footer /> : undefined}
          </>
        ) : undefined}
      </div>
      <Modal
        outerClassName={styles.modalOuter}
        visible={state.modalVisibleAward}
        softClose={false}
        onClose={handleModalAwardClose}
      >
        <UserAwardsModalContent
          awards={state.awards}
          onClose={handleModalAwardClose}
          onGoToDashboard={handleGoToDashboard}
        />
      </Modal>
      <Modal
        outerClassName={styles.modalOuter}
        visible={state.modalVisibleLevelUp}
        softClose={false}
        onClose={handleModalLevelUpClose}
      >
        <UserLevelUpModalContent userGeneral={reduxUser.data.state?.general} onClose={handleModalLevelUpClose} />
      </Modal>
    </>
  )
}

export default withRouter(Wrapper)
