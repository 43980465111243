import React from 'react'
import cn from 'classnames'
import styles from './style.module.sass'

const ClaimLessonModalContent = ({
  nextLesson,
  canCloseModal,
  skillPoints,
  skillCoins,
  lessonsCompleted,
  hasQuiz,
  onBtnClickNext,
  onBtnClickQuiz,
  onBtnClickClose
}) => {
  return (
    <div>
      {lessonsCompleted ? (
        <div className={cn(styles.title)}>All Lesson Completed 👏</div>
      ) : (
        <div className={cn(styles.title)}>Lesson Complete 👍</div>
      )}
      <div className={styles.line}>
        <div className={styles.icon}>
          <img src='/images/icons/skill-points-icon-100.png' alt='Skill Points' style={{ width: 48 }} />
        </div>
        <div className={styles.details}>
          <div className={styles.info}>{skillPoints} skill points earned</div>
        </div>
      </div>
      <div className={styles.line}>
        <div className={styles.icon}>
          <img src='/images/icons/skill-coins-icon-100.png' alt='Skill Coins' style={{ width: 48 }} />
        </div>
        <div className={styles.details}>
          <div className={styles.info}>{skillCoins} skill coins earned</div>
        </div>
      </div>
      {/* {lessonsCompleted && hasQuiz ? (
        <div className={styles.line}>
          <div className={styles.icon}>
            <img src='/images/icons/quiz-icon-100.png' alt='Quiz' style={{ width: 48 }} />
          </div>
          <div className={styles.details}>
            <div className={styles.info}>Quiz Unlocked!</div>
          </div>
        </div>
      ) : undefined} */}
      <div className={styles.btns}>
        {!lessonsCompleted && nextLesson ? (
          <button className={cn('button', styles.button)} onClick={onBtnClickNext}>
            Next Lesson
          </button>
        ) : undefined}
        {/* {lessonsCompleted && hasQuiz ? (
          <button className={cn('button', styles.button)} onClick={onBtnClickQuiz}>
            Start Quiz
          </button>
        ) : undefined} */}
        {canCloseModal ? (
          <button className={cn('button-stroke', styles.button)} onClick={onBtnClickClose}>
            Close
          </button>
        ) : undefined}
      </div>
    </div>
  )
}

export default ClaimLessonModalContent
