import { createSlice } from '@reduxjs/toolkit'
import { getCoreStateTemplate } from 'lib/templates'

const initialState = getCoreStateTemplate()

export const coreSlice = createSlice({
  name: 'core',
  initialState,
  reducers: {
    setFacet: (state, action) => {
      state.facet = action.payload.facet
      state.facetData = action.payload.facetData
    },
    setWrapper: (state, action) => {
      state.headerEnabled = action.payload.headerEnabled
      state.footerEnabled = action.payload.footerEnabled
    },
    setUser: (state, action) => {
      state.user = action.payload
    },
    setUserData: (state, action) => {
      state.user.data = action.payload
    },
    setHeaderBackButton: (state, action) => {
      state.backUrl = action.payload
    },
    setCourseData: (state, action) => {
      state.course = action.payload
    },
    setCourseCatalogue: (state, action) => {
      state.courseCatalogue.initialized = action.payload.initialized || false
      state.courseCatalogue.all.data = action.payload.all || []
      state.courseCatalogue.featured.data = action.payload.featured || []
      state.courseCatalogue.recent.data = action.payload.recent || []
      state.courseCatalogue.newbies.data = action.payload.newbies || []
      state.courseCatalogue.nft.data = action.payload.nft || []
      state.courseCatalogue.buildspace.data = action.payload.buildspace || []
      state.courseCatalogue.metaschool.data = action.payload.metaschool || []
      state.courseCatalogue.metability.data = action.payload.metability || []
    },
    setEvents: (state, action) => {
      state.events.initialized = true
      state.events.data = action.payload
    },
    setWeb3Data: (state, action) => {
      state.web3.ethAddress = action.payload.ethAddress
      state.web3.isWeb3Enabled = action.payload.isWeb3Enabled
      state.web3.isMetaMask = action.payload.isMetaMask
      state.web3.chain = action.payload.chain
    },
    setMoralisUser: (state, action) => {
      state.web3.user = action.payload
    },
    reset: (state) => {
      state = initialState
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  setFacet,
  setWrapper,
  setUser,
  setUserData,
  setHeaderBackButton,
  setWeb3Data,
  setMoralisUser,
  setCourseData,
  setCourseCatalogue,
  setEvents,
  reset
} = coreSlice.actions
export default coreSlice.reducer
