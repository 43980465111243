const Enums = {
  values: {
    ROOT_TAG: 'root',
    STRING_DEFAULT: '',
    NUMBER_DEFAULT: 0,
    NULL: null,
    AGILITE_LOGO_ALT: 'Agilit-e Logo'
  },
  environment: {
    NODE_PRODUCTION: 'production',
    FIREBASE_LOCAL: 'firebase-local',
    FIREBASE_PROD: 'firebase-prod'
  },
  triggers: {
    ENTER: 'enter',
    CUSTOM: 'custom'
  },
  triggerKeys: {
    SUPPORT_ICON_VISIBLE: 'support_icon_visible',
    ACADEMY_MAIN: 'academy_main'
  },
  facets: {
    ANONYMOUS_DEFAULT: 'quiz',
    LOADING: 'loading',
    HOME: 'home',
    CLASSES: 'classes',
    LESSON_CONTENT: 'lesson_content',
    QUIZ: 'quiz'
  },
  loginFacets: {
    OPTIONS: 'login_options',
    EMAIL_LINK: 'login_email_link',
    EMAIL_LINK_SUCCESS: 'login_email_link_success',
    FACEBOOK: 'login_facebook',
    GOOGLE: 'login_google'
  },
  reqHeaders: {
    API_KEY: 'api-key',
    ACTION: 'action',
    TEMPLATE_KEY: 'template-key',
    TIER_KEY: 'tier-key',
    EMAIL: 'email',
    CONTENT_TYPE: 'Content-Type',
    CONTENT_JSON: 'application/json'
  },
  reqActions: {
    INIT_APP: 'init-app',
    GET_LESSON: 'get-lesson',
    GET_QUIZ_DATA: 'get-quiz-data',
    GET_QUIZ_QUESTIONS: 'get-quiz-questions',
    INIT_USER_SESSION: 'init-user-session',
    JOIN_ALPHA_PROGRAM: 'join-alpha-program',
    UPLOAD_PROFILE_PIC: 'upload-profile-pic',
    POST_ANALYTICS: 'post_analytics',
    QRY_ANALYTICS: 'qry_analytics'
  },
  reqTypes: {
    GET: 'get',
    POST: 'post'
  },
  courseStatus: {
    NEW: 'new',
    ACTIVE: 'active',
    COMPLETE: 'complete'
  },
  courseStatusLabel: {
    new: 'Not Started',
    active: 'In Progress',
    complete: 'Completed'
  },
  messages: {}
}

export default Enums
