import React from 'react'
import { Row, Col, Skeleton } from 'antd'

import styles from './style.module.sass'

const LoadingIndicator = ({ msg }) => {
  msg = msg || 'Loading'

  return (
    <div>
      <Row>
        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 8, offset: 8 }} className={styles.loadingText}>
          <h2>{`${msg}...`}</h2>
        </Col>
        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 8, offset: 8 }}>
          <Skeleton active />
        </Col>
      </Row>
    </div>
  )
}

export default LoadingIndicator
