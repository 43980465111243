import React from 'react'
import cn from 'classnames'
import styles from './style.module.sass'

const QuizResultModalContent = ({
  title,
  skillPoints,
  skillCoins,
  questionsTotal,
  mininumQuestionsRequired,
  questionsCorrect,
  onBtnClickClose
}) => {
  return (
    <div>
      <div className={cn(styles.title)}>{title}</div>
      <div
        className={cn(styles.title)}
      >{`${questionsTotal} questions answered (Min ${mininumQuestionsRequired} required)`}</div>
      <div
        className={cn(styles.title)}
      >{`${questionsCorrect} out of ${questionsTotal} questions answered correctly`}</div>
      <div className={styles.line}>
        <div className={styles.icon}>
          <img src='/images/icons/skill-points-icon-100.png' alt='Skill Points' style={{ width: 48 }} />
        </div>
        <div className={styles.details}>
          <div className={styles.info}>{skillPoints} skill points earned</div>
        </div>
      </div>
      <div className={styles.line}>
        <div className={styles.icon}>
          <img src='/images/icons/skill-coins-icon-100.png' alt='Skill Coins' style={{ width: 48 }} />
        </div>
        <div className={styles.details}>
          <div className={styles.info}>{skillCoins} skill coins earned</div>
        </div>
      </div>
      <div className={styles.btns}>
        <button className={cn('button-stroke', styles.button)} onClick={onBtnClickClose}>
          Close
        </button>
      </div>
    </div>
  )
}

export default QuizResultModalContent
