import React, { useCallback, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import OutsideClickHandler from 'react-outside-click-handler'
import cn from 'classnames'

import styles from './style.module.sass'
import Icon from 'lib/icons-svg'

const Modal = ({ outerClassName, containerClassName, visible, softClose, onClose, children }) => {
  const escClose = useCallback(
    (e) => {
      if (e.keyCode === 27 && softClose) {
        onClose()
      }
    },
    [onClose, softClose]
  )

  const outsideClose = useCallback(() => {
    if (softClose) onClose()
  }, [onClose, softClose])

  const forceClose = useCallback(
    (e) => {
      onClose()
    },
    [onClose]
  )

  useEffect(() => {
    document.addEventListener('keydown', escClose, false)
    return () => {
      document.removeEventListener('keydown', escClose, false)
    }
  }, [escClose])

  useEffect(() => {
    if (visible) {
      const target = document.querySelector('#modal')
      disableBodyScroll(target)
    } else {
      clearAllBodyScrollLocks()
    }
  }, [visible])

  return createPortal(
    visible && (
      <div className={styles.modal} id='modal'>
        <div className={cn(styles.outer, outerClassName)}>
          <OutsideClickHandler onOutsideClick={outsideClose}>
            <div className={cn(styles.container, containerClassName)}>
              {children}
              <button className={styles.close} onClick={forceClose}>
                <Icon name='close' size='14' />
              </button>
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    ),
    document.body
  )
}

export default Modal
