import { configureStore } from '@reduxjs/toolkit'

import quizReducer from './screens/Quiz/-main/-reducer'
import coreReducer from '-core/core-reducer'
import Enums from 'lib/enums'

// Configure Reducers
const reducer = {
  core: coreReducer,
  quiz: quizReducer
}

// Configure Redux Store
export const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== Enums.environment.NODE_PRODUCTION
})
