import React, { useEffect, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import LoadingIndicator from 'components/LoadingIndicator'
import LessonContent from 'components/LessonContent'
import NavToolbar from 'components/NavToolbar'
import { setWrapper } from '-core/core-reducer'
import { getCourseContent, getCourseDoc } from 'lib/firebase'

const CourseLesson = () => {
  const dispatch = useDispatch()
  const params = useParams()

  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    course: null,
    lessonContent: null
  })

  const breadcrumbs = [
    {
      title: 'Home',
      url: '/'
    },
    {
      title: 'Course Detail',
      url: `/courses/${params.courseId}`
    },
    {
      title: 'Course Lesson'
    }
  ]

  // Runs on load of component
  useEffect(() => {
    async function init() {
      let lessonContent = null
      let course = null

      try {
        dispatch(setWrapper({ headerEnabled: true, footerEnabled: false }))
        setState({ lessonContent: null })

        // Fetch Course & Lesson & update state
        course = await getCourseDoc(params.courseId, true, true)
        lessonContent = await getCourseContent(params.courseId, params.lessonId)
        setState({ course, lessonContent })
      } catch (e) {
        console.error(e)
      }
    }

    init()
    // eslint-disable-next-line
  }, [params.lessonId])

  return (
    <>
      <NavToolbar breadcrumbs={breadcrumbs} backTitle='Back to Course Detail' backUrl={`/courses/${params.courseId}`} />
      {!state.lessonContent ? (
        <LoadingIndicator />
      ) : (
        <LessonContent
          course={state.course}
          lessonContent={state.lessonContent}
          lessonId={params.lessonId}
          levelId={params.levelId}
        />
      )}
    </>
  )
}

export default CourseLesson
