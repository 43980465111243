import React, { useEffect, useReducer } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import Axios from 'agilite-utils/axios'

import { setQuizData, setGameData } from './-reducer'
import PrepPhase from '../1-PrepPhase'
import QuizPhase from '../2-QuizPhase'
import Enums from 'lib/enums'
import LoadingIndicator from 'components/LoadingIndicator'
import { executeAgiliteRequest, processPhase } from 'lib/utils'
import { setWrapper } from '-core/core-reducer'
import NavToolbar from 'components/NavToolbar'

const Quiz = () => {
  const history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()

  const courseData = useSelector((state) => state.core.sessionData)
  const gameData = useSelector((state) => state.quiz.gameData)
  const isActive = useSelector((state) => state.quiz.isActive)
  const userData = useSelector((state) => state.quiz.userData)
  const quizFlow = useSelector((state) => state.quiz.flow)

  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    nftInitialized: true,
    resourceCards: [],
    actionCards: [],
    initialized: false,
    course: {},
    level: {}
  })

  const breadcrumbs = [
    {
      title: 'Home',
      url: '/'
    },
    {
      title: 'Course Detail',
      url: `/courses/${params.courseId}`
    },
    {
      title: 'Quiz'
    }
  ]

  // Runs on load of Component
  useEffect(() => {
    let axiosRef = null

    async function fetchData() {
      let result = null
      let dispatchData = null
      let gameDataResult = null
      let userResourceCards = []
      let userActionCards = []
      let userCardDeck = []
      let tmpCourse = null
      let tmpLevels = null
      let tmpLevel = null
      let errMsg = null

      try {
        if (!state.nftInitialized) return

        // Disable Header/Footer
        dispatch(setWrapper({ headerEnabled: true, footerEnabled: false }))

        // Fetch data from Agilit-e
        axiosRef = Axios.CancelToken.source()
        result = await executeAgiliteRequest(axiosRef.token, Enums.reqActions.GET_QUIZ_DATA, 'default')
        result = result.data

        // Combine Cards and prep Game Data
        gameDataResult = await processPhase(result.quizFlow, gameData, userData, true)

        dispatchData = {
          updatedAt: Date.now(),
          isActive: true,
          gameData: gameDataResult,
          userData: {
            ...userData,
            resourceCards: userResourceCards,
            actionCards: userActionCards,
            cardDeck: userCardDeck
          },
          flow: result.quizFlow
        }

        dispatch(setQuizData(dispatchData))

        // Fetch active course
        tmpCourse = courseData.courses.find((item) => item.key === params.courseId)

        if (!tmpCourse) {
          errMsg = `No course found for id: ${params.courseId}`
          throw new Error(errMsg)
        }

        tmpLevels = courseData.courseLevels[`${tmpCourse.category}_${tmpCourse.key}`]
        tmpLevel = tmpLevels.find((item) => item.key === params.levelId)

        if (!tmpLevel) {
          errMsg = `No course level found for id: ${params.levelId} - for course: ${params.courseId}`
          throw new Error(errMsg)
        }

        setState({
          initialized: true,
          course: tmpCourse,
          level: tmpLevel
        })
      } catch (e) {
        console.error(e)
      }
    }

    fetchData()

    return () => {
      if (axiosRef) axiosRef.cancel()
    }

    // eslint-disable-next-line
  }, [state.nftInitialized])

  const handleNextPhase = async () => {
    let dispatchData = null
    let data = null

    try {
      data = await processPhase(quizFlow, gameData, userData)

      dispatchData = {
        updatedAt: Date.now(),
        data
      }

      dispatch(setGameData(dispatchData))
    } catch (e) {
      console.error(e)
    }
  }

  const handleCloseQuiz = () => {
    try {
      history.replace(`/courses/${params.courseId}`)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div id='quizMain'>
      {!isActive ? (
        <LoadingIndicator />
      ) : (
        <>
          {['phase_prep', 'phase_result'].includes(gameData.stepKey) ? (
            <>
              <NavToolbar
                breadcrumbs={breadcrumbs}
                backTitle='Back to Course Detail'
                backUrl={`/courses/${params.courseId}`}
              />
              {gameData.stepKey === 'phase_prep' ? (
                <PrepPhase onNextPhase={handleNextPhase} course={state.course} level={state.level} />
              ) : undefined}
              {gameData.stepKey === 'phase_result' ? (
                <PrepPhase onNextPhase={handleNextPhase} course={state.course} level={state.level} />
              ) : undefined}
            </>
          ) : undefined}
          {gameData.stepKey === 'phase_quiz' ? (
            <QuizPhase onNextPhase={handleNextPhase} onCloseQuiz={handleCloseQuiz} level={state.level} />
          ) : undefined}
        </>
      )}
    </div>
  )
}

export default Quiz
