import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import styles from './style.module.sass'

import { setWrapper } from '-core/core-reducer'
import SlideDeckCourses from 'components/SlideDeckCourses'
import LoadingIndicator from 'components/LoadingIndicator'
import CarouselCourses from 'components/CarouselCourses'

const BrowseCourses = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const reduxCatalogue = useSelector((state) => state.core.courseCatalogue)

  // Runs on load of component
  useEffect(() => {
    function initComponent() {
      try {
        dispatch(setWrapper({ headerEnabled: true, footerEnabled: true }))
      } catch (e) {
        console.error(e)
      }
    }

    initComponent()
    // eslint-disable-next-line
  }, [])

  const handleCourseClick = (id, catalogueType) => {
    try {
      switch (catalogueType) {
        case 'event':
          history.replace(`/events/${id}/overview`)
          break
        default:
          history.replace(`/courses/${id}/overview`)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      {!reduxCatalogue.initialized ? (
        <LoadingIndicator />
      ) : (
        <>
          {reduxCatalogue.featured.data.length > 0 ? (
            <CarouselCourses
              data={reduxCatalogue.featured.data}
              title={reduxCatalogue.featured.title}
              onCourseClick={handleCourseClick}
            />
          ) : undefined}

          {reduxCatalogue.recent.data.length > 0 ? (
            <SlideDeckCourses
              data={reduxCatalogue.recent.data}
              title={reduxCatalogue.recent.title}
              onCourseClick={handleCourseClick}
            />
          ) : undefined}

          <div className={styles.spacer} />

          {reduxCatalogue.newbies.data.length > 0 ? (
            <SlideDeckCourses
              data={reduxCatalogue.newbies.data}
              title={reduxCatalogue.newbies.title}
              onCourseClick={handleCourseClick}
            />
          ) : undefined}

          <div className={styles.spacer} />

          {reduxCatalogue.nft.data.length > 0 ? (
            <SlideDeckCourses
              data={reduxCatalogue.nft.data}
              title={reduxCatalogue.nft.title}
              onCourseClick={handleCourseClick}
            />
          ) : undefined}

          <div className={styles.spacer} />

          {reduxCatalogue.buildspace.data.length > 0 ? (
            <SlideDeckCourses
              data={reduxCatalogue.buildspace.data}
              title={reduxCatalogue.buildspace.title}
              onCourseClick={handleCourseClick}
            />
          ) : undefined}

          <div className={styles.spacer} />

          {reduxCatalogue.metaschool.data.length > 0 ? (
            <SlideDeckCourses
              data={reduxCatalogue.metaschool.data}
              title={reduxCatalogue.metaschool.title}
              onCourseClick={handleCourseClick}
            />
          ) : undefined}

          {reduxCatalogue.metability.data.length > 0 ? (
            <SlideDeckCourses
              data={reduxCatalogue.metability.data}
              title={reduxCatalogue.metability.title}
              onCourseClick={handleCourseClick}
            />
          ) : undefined}
        </>
      )}
    </>
  )
}

export default BrowseCourses
