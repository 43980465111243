import React from 'react'
import cn from 'classnames'
import styles from './style.module.sass'
import { Col, Row } from 'antd'

const UserLevelUpModalContent = ({ userGeneral, onClose }) => {
  return (
    <>
      <div className={cn(styles.title)}>You've Leveled Up! 👏</div>
      <Row justify='center'>
        <Col xs={8}>
          <img src='/images/icons/awards-icon-400.gif' alt='Awards' className={styles.icon} />
        </Col>
      </Row>
      <Row justify='center'>
        <Col xs={24} className={styles.subTitle}>
          <div>Congratulations...you've reached</div>
        </Col>
        <Col xs={24} className={styles.levelTitle}>
          <div>{`LEVEL ${userGeneral.skillLevel}`}</div>
        </Col>
      </Row>
      <Row justify='center' className={styles.btns}>
        <Col xs={20}>
          <button className={cn('button-stroke', styles.button)} onClick={onClose}>
            Close
          </button>
        </Col>
      </Row>
    </>
  )
}

export default UserLevelUpModalContent
