import 'lib/firebase'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { store } from 'redux-store'
import { MoralisProvider } from 'react-moralis'

import Routes from '-core/components/Routes'
import './style.module.sass'
import 'antd/dist/antd.css'

const APP_ID = process.env.REACT_APP_MORALIS_APPLICATION_ID
const SERVER_URL = process.env.REACT_APP_MORALIS_SERVER_URL

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MoralisProvider appId={APP_ID} serverUrl={SERVER_URL}>
        <Routes />
      </MoralisProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
