import React, { useEffect, useReducer } from 'react'
import { Link, useHistory } from 'react-router-dom'
import cn from 'classnames'
import OutsideClickHandler from 'react-outside-click-handler'
import { useSelector } from 'react-redux'
import { signOut } from 'firebase/auth'
import { auth } from 'lib/firebase'
import CopyToClipboard from 'react-copy-to-clipboard'
import { message } from 'antd'
import styles from './style.module.sass'

import { abbreviateWalletAddress, formatNumber } from 'lib/utils'
import Icon from 'lib/icons-svg'
import Theme from 'components/Theme'

const User = ({ backUrl }) => {
  const history = useHistory()
  const reduxUser = useSelector((state) => state.core.user)

  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    visible: false,
    addressAbbr: '',
    skillPoints: 0,
    skillCoins: 0,
    skillLevel: 0
  })

  useEffect(() => {
    async function init() {
      let addressAbbr = null
      let skillPoints = 0
      let skillCoins = 0
      let skillLevel = 0

      try {
        if (reduxUser.data?.state.general) {
          skillPoints = formatNumber(reduxUser.data.state.general.skillPoints)
          skillCoins = formatNumber(reduxUser.data.state.general.skillCoins)
          skillLevel = reduxUser.data.state.general.skillLevel

          addressAbbr = reduxUser.data.state.general.ethAddress
          if (addressAbbr) addressAbbr = await abbreviateWalletAddress(addressAbbr)
        }

        setState({ addressAbbr, skillPoints, skillCoins, skillLevel })
      } catch (e) {
        console.error(e)
      }
    }

    init()
    // eslint-disable-next-line
  }, [reduxUser])

  const items = [
    {
      title: 'Profile',
      icon: 'user',
      url: '/profile'
    },
    // {
    //   title: 'My items',
    //   icon: 'image',
    //   url: '/item'
    // },
    {
      title: 'Dark theme',
      icon: 'bulb',
      linkType: 'theme'
    },
    {
      title: 'Logout',
      icon: 'exit',
      linkType: 'click',
      functionCall: async () => {
        await signOut(auth)
        history.replace('/')
      }
    }
  ]

  return (
    <OutsideClickHandler onOutsideClick={() => setState({ visible: false })}>
      <div className={cn(styles.user)}>
        <div
          className={backUrl === '' ? styles.head2 : styles.head}
          onClick={() => setState({ visible: !state.visible })}
        >
          <div className={styles.avatar}>
            <img
              src={
                reduxUser.data?.profilePicId
                  ? `https://apidemo.agilite.io/files/${reduxUser.data.profilePicId}`
                  : '/images/icons/metability-profile-default-icon-100.jpg'
              }
              alt='User Avatar'
            />
          </div>
          <div className={styles.wallet}>
            <div style={{ margin: 5 }}>LVL {state.skillLevel}</div>
          </div>
        </div>
        {state.visible && (
          <div className={styles.body}>
            <div className={styles.name}>{reduxUser.data?.username.toUpperCase() || 'ANONYMOUS'}</div>
            {reduxUser.data?.state.general.ethAddress ? (
              <div className={styles.code}>
                <div className={styles.number}>{state.addressAbbr}</div>
                <CopyToClipboard
                  text={reduxUser.data.state.general.ethAddress}
                  onCopy={() => message.success('Eth address copied to clipboard')}
                >
                  <button className={styles.copy}>
                    <Icon name='copy' size='16' />
                  </button>
                </CopyToClipboard>
              </div>
            ) : undefined}
            <div className={styles.wrap}>
              <div className={styles.line}>
                <div className={styles.preview}>
                  <img src='/images/icons/skill-level-icon-100.png' alt='Skill Level' />
                </div>
                <div className={styles.details}>
                  <div className={styles.info}>Skill Level</div>
                  <div className={styles.price}>{state.skillLevel}</div>
                </div>
              </div>
              <div className={styles.line}>
                <div className={styles.preview}>
                  <img src='/images/icons/skill-points-icon-100.png' alt='Skill Points' />
                </div>
                <div className={styles.details}>
                  <div className={styles.info}>Skill Points</div>
                  <div className={styles.price}>{state.skillPoints}</div>
                </div>
              </div>
              <div className={styles.line}>
                <div className={styles.preview}>
                  <img src='/images/icons/skill-coins-icon-100.png' alt='Skill Coins' />
                </div>
                <div className={styles.details}>
                  <div className={styles.info}>Skill Coins</div>
                  <div className={styles.price}>{state.skillCoins}</div>
                </div>
              </div>
            </div>
            <div className={styles.menu}>
              {items.map((x, index) =>
                x.url ? (
                  x.url.startsWith('http') ? (
                    <a className={styles.item} href={x.url} rel='noopener noreferrer' key={index}>
                      <div className={styles.icon}>
                        <Icon name={x.icon} size='20' />
                      </div>
                      <div className={styles.text}>{x.title}</div>
                    </a>
                  ) : (
                    <Link
                      className={styles.item}
                      to={x.url}
                      onClick={() => setState({ visible: !state.visible })}
                      key={index}
                    >
                      <div className={styles.icon}>
                        <Icon name={x.icon} size='20' />
                      </div>
                      <div className={styles.text}>{x.title}</div>
                    </Link>
                  )
                ) : x.linkType === 'theme' ? (
                  <div className={styles.item} key={index}>
                    <div className={styles.icon}>
                      <Icon name={x.icon} size='20' />
                    </div>
                    <div className={styles.text}>{x.title}</div>
                    <Theme className={styles.theme} />
                  </div>
                ) : x.linkType === 'click' ? (
                  <div className={styles.item} key={index} onClick={x.functionCall} style={{ cursor: 'pointer' }}>
                    <div className={styles.icon}>
                      <Icon name={x.icon} size='20' />
                    </div>
                    <div className={styles.text}>{x.title}</div>
                  </div>
                ) : undefined
              )}
            </div>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  )
}

export default User
