import React, { useEffect, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import Content from './Content'
import NavToolbar from 'components/NavToolbar'
import { setWrapper } from '-core/core-reducer'
import { getCourseDoc } from 'lib/firebase'

const CourseDetail = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const userState = useSelector((state) => state.core.user.data.state)

  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    initialized: false,
    course: {},
    levelOptions: []
  })

  const breadcrumbs = [
    {
      title: 'Home',
      url: '/'
    },
    {
      title: 'Course Detail'
    }
  ]

  // Runs on load of component
  useEffect(() => {
    async function init() {
      const tmpOptions = []

      let course = null
      let userLevel = null

      try {
        dispatch(setWrapper({ headerEnabled: true, footerEnabled: true }))

        // Fetch course from Firebase
        course = await getCourseDoc(params.courseId, true, true)
        // Fetch Course Lessons and add quiz status
        for (const level of course.levels) {
          tmpOptions.push(level.label)
          userLevel = userState.courses[course.id]?.levels[level.id]

          if (userLevel) {
            level.quizEnabled = userLevel.lessonsCompleted && !userLevel.quizComplete
            level.quizComplete = userLevel.quizComplete
          } else {
            level.quizEnabled = false
            level.quizComplete = false
          }
        }

        // Update State
        setState({
          initialized: true,
          course,
          levelOptions: tmpOptions
        })
      } catch (e) {
        console.error(e)
      }
    }

    init()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <NavToolbar breadcrumbs={breadcrumbs} backTitle='Back to Home' backUrl='/' />
      <Content parentState={state} />
    </>
  )
}

export default CourseDetail
