import React, { useEffect, useReducer } from 'react'
import { Card, Col, Row, Result } from 'antd'
import cn from 'classnames'
import useDarkMode from 'use-dark-mode'

import styles from './style.module.sass'
import { useDispatch, useSelector } from 'react-redux'
import { getQuizQuestion, processQuizAnswer } from 'lib/utils'
import { setQuestions } from 'screens/Quiz/-main/-reducer'
import { getQuizInitialState, getQuizNextQuestionState, getQuizStopTimerState } from 'lib/templates'

const Quiz = ({ onEndOfRound }) => {
  const dispatch = useDispatch()
  const darkMode = useDarkMode(false)
  const questions = useSelector((state) => state.quiz.questions)

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    getQuizInitialState(darkMode.value)
  )

  // Fetch Question and start
  useEffect(() => {
    async function init() {
      let tmpQuestion = null

      let isStarted = false
      let timeoutEnabled = false
      let questionId = ''
      let prevQuestionId = ''
      let question = ''
      let answers = []

      try {
        // Return next question if there are any, else finalize
        tmpQuestion = await getQuizQuestion(questions)

        if (tmpQuestion) {
          // Ask Next Question
          isStarted = true
          timeoutEnabled = false
          questionId = tmpQuestion.id
          prevQuestionId = questionId
          question = tmpQuestion.question
          answers = tmpQuestion.answers

          setState({ isStarted, timeoutEnabled, questionId, prevQuestionId, question, answers })
        } else {
          onEndOfRound()
        }
      } catch (e) {
        console.error(e)
      }
    }

    init()

    // eslint-disable-next-line
  }, [])

  // Manages Progress Indicator for Question's time
  useEffect(() => {
    function updateProgress() {
      try {
        if (!state.timeoutEnabled) return

        if (state.progress <= 0) {
          handleAnswerTimeout()
        } else {
          setState({ progress: state.progress - 0.09 })

          if (state.progress < 20) {
            setState({ progressColor: 'red' })
          } else if (state.progress < 60) {
            setState({ progressColor: 'orange' })
          }
        }
      } catch (e) {
        console.error(e)
      }
    }

    let timeout = setTimeout(updateProgress, 10)

    // On Unmount
    return () => {
      clearTimeout(timeout)
    }

    // eslint-disable-next-line
  }, [state.progress, state.timeoutEnabled])

  const handleAnswerClick = async (answerId) => {
    let resultTitle = 'Correct'
    let resultStatus = 'success'
    let questionId = null
    let question = null
    let answers = null

    try {
      question = questions.find((item) => item.id === state.questionId)

      if (answerId !== question.correct) {
        resultTitle = 'Incorrect'
        resultStatus = 'error'
      }

      setState(getQuizStopTimerState(resultTitle, resultStatus))
      const { nextQuestion, updatedQuestions } = await handleProcessQuestion(answerId)
      if (!nextQuestion) return onEndOfRound(updatedQuestions)

      setTimeout(() => {
        // Ask Next Question
        questionId = nextQuestion.id
        question = nextQuestion.question
        answers = nextQuestion.answers

        setState(getQuizNextQuestionState(questionId, question, answers))
      }, 1000)
    } catch (e) {
      console.error(e)
    }
  }

  const handleAnswerTimeout = async () => {
    let questionId = null
    let question = null
    let answers = null
    let resultTitle = 'Out Of Time'
    let resultStatus = 'error'

    try {
      // User didn't answer in time, display result for 1 second and move to next question
      setState(getQuizStopTimerState(resultTitle, resultStatus))
      const { nextQuestion, updatedQuestions } = await handleProcessQuestion()
      if (!nextQuestion) return onEndOfRound(updatedQuestions)

      setTimeout(() => {
        // Ask Next Question
        questionId = nextQuestion.id
        question = nextQuestion.question
        answers = nextQuestion.answers

        setState(getQuizNextQuestionState(questionId, question, answers))
      }, 1000)
    } catch (e) {
      console.error(e)
    }
  }

  const handleProcessQuestion = async (answerId) => {
    return new Promise((resolve, reject) => {
      ;(async () => {
        let updatedQuestions = null
        let nextQuestion = null

        try {
          // Populate Answer and return updated questions for storing
          updatedQuestions = await processQuizAnswer(questions, state.questionId, answerId)
          dispatch(setQuestions(updatedQuestions))

          // Return next question if there are any, else finalize
          nextQuestion = await getQuizQuestion(updatedQuestions)
          resolve({ nextQuestion, updatedQuestions })
        } catch (e) {
          reject(e)
        }
      })()
    })
  }

  const returnResultTitle = () => {
    return <p className={cn(styles.title)}>{state.question}</p>
  }

  return (
    <div>
      {/* {!state.displayResult ? (
        <Progress
          percent={state.progress}
          showInfo={false}
          strokeColor={state.progressColor}
          trailColor={state.trailColor}
        />
      ) : undefined} */}
      <Card title={returnResultTitle()} className={styles.card}>
        {state.displayResult ? (
          <Row>
            <Col xs={24}>
              <Result
                className={styles.displayResult}
                status={state.resultStatus}
                title={state.resultTitle}
                extra={[]}
              />
            </Col>
          </Row>
        ) : (
          <Row>
            {state.answers.map((item, index) => (
              <Col
                key={index}
                xs={22}
                sm={22}
                md={11}
                lg={11}
                xl={11}
                xxl={11}
                className={styles.answerColumn}
                onClick={() => handleAnswerClick(item.id)}
              >
                <Card className={styles.cardAnswer}>
                  <p className={cn(styles.answer)}>{item.value}</p>
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </Card>
    </div>
  )
}

export default Quiz
