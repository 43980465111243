import React from 'react'
import cn from 'classnames'
import styles from './style.module.sass'
import { Col, Row, List, Avatar, Divider } from 'antd'

const UserAwardsModalContent = ({ awards, onClose, onGoToDashboard }) => {
  return (
    <>
      <div className={cn(styles.title)}>Congratulations! 👏</div>
      <Row justify='center'>
        <Col xs={8}>
          <img src='/images/icons/awards-icon-400.gif' alt='Awards' className={styles.icon} />
        </Col>
      </Row>
      <Row justify='center'>
        <Col xs={24} className={styles.subTitle}>
          <div>You have completed the following courses and earned awards:</div>
        </Col>
      </Row>
      <Row>
        <Col xs={24} className={styles.content}>
          <List
            itemLayout='horizontal'
            dataSource={awards}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar src={item.providerLogo} />}
                  title={<div className={styles.listItemTitle}> {item.provider}</div>}
                  description={
                    <>
                      <div className={styles.listItemDescription}>{item.courseTitle}</div>
                      <Row>
                        <Col xs={24}>
                          <Divider className={styles.divider}>Awards</Divider>
                        </Col>
                      </Row>
                      {item.awardDetail.length > 0 ? (
                        <Row className={styles.awardDetail}>
                          <Col xs={24}>
                            {item.awardDetail.map((item2, index) => (
                              <Row key={index}>
                                <Col xs={4} md={3}>
                                  {item2.awardType === 'nft' ? (
                                    <Avatar src='/images/icons/nft-icon-100.png' />
                                  ) : undefined}
                                  {item2.awardType === 'skill-points' ? (
                                    <Avatar src='/images/icons/skill-points-icon-100.png' />
                                  ) : undefined}
                                  {item2.awardType === 'skill-coins' ? (
                                    <Avatar src='/images/icons/skill-coins-icon-100.png' />
                                  ) : undefined}
                                </Col>
                                <Col xs={20} md={21}>
                                  <div className={styles.awardLabel}>{item2.awardLabel}</div>
                                </Col>
                              </Row>
                            ))}
                          </Col>
                        </Row>
                      ) : (
                        <Row className={styles.awardDetail}>
                          <Col xs={24}>
                            <Row>
                              <Col xs={24}>
                                <center>
                                  <div className={styles.awardLabel}>No Awards. Sorry</div>
                                </center>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )}
                    </>
                  }
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row justify='center'>
        <Col xs={24} className={styles.footerTitle}>
          <div>You will be able to view your achievements via your User Dashboard</div>
        </Col>
      </Row>
      <Row justify='center' className={styles.btns}>
        <Col xs={20}>
          <button className={cn('button', styles.button)} onClick={onGoToDashboard}>
            Go To User Dashboard
          </button>
        </Col>
        <Col xs={20}>
          <button className={cn('button-stroke', styles.button)} onClick={onClose}>
            Close
          </button>
        </Col>
      </Row>
    </>
  )
}

export default UserAwardsModalContent
