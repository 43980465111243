import React, { useEffect, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { message } from 'antd'
import {
  sendSignInLinkToEmail,
  signInWithRedirect,
  getRedirectResult,
  FacebookAuthProvider,
  GoogleAuthProvider
} from 'firebase/auth'

import NavToolbar from 'components/NavToolbar'
import { setWrapper } from '-core/core-reducer'
import LoginEmailLink from './LoginEmailLink'
import LoginEmailSuccess from './LoginEmailSuccess'
import LoginOptions from './LoginOptions'

import Enums from 'lib/enums'
import { auth } from 'lib/firebase'

const LoginUser = ({ forcedLogin }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname } = useLocation()

  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    facet: Enums.loginFacets.OPTIONS,
    disableForm: false,
    authError: true,
    loginType: pathname === '/register' ? 'register' : 'login'
  })

  const breadcrumbs = [
    {
      title: 'Home',
      url: '/'
    },
    {
      title: state.loginType === 'register' ? 'Register User' : 'Login User'
    }
  ]

  // Runs on load of component
  useEffect(() => {
    async function init() {
      let result = null
      let errMsg = null

      try {
        if (!forcedLogin) {
          // Check if an auth provider redirect occurred
          result = await getRedirectResult(auth)

          // If redirecting back to login or register, reset to Home Page
          if (result?.operationType && ['/register', '/login'].includes(pathname.toLowerCase())) {
            return history.replace('/')
          }
        }

        setState({ authError: false })
        dispatch(setWrapper({ headerEnabled: true, footerEnabled: true }))
      } catch (e) {
        switch (e.code) {
          case 'auth/account-exists-with-different-credential':
            // User logged in using an incorrect provider
            errMsg =
              "You created your Metability account using a method different to the one you used now. Please try a different method, or alternatively, select the 'Quick Email' option."
            message.error({
              content: errMsg,
              duration: 10,
              className: 'message-prompt'
            })
            break
          default:
            console.error(e.message)
        }
      }
    }

    init()
    // eslint-disable-next-line
  }, [])

  const handleSelectOption = async (facet) => {
    let provider = null

    try {
      // First Set Provider
      switch (facet) {
        case Enums.loginFacets.FACEBOOK:
          provider = new FacebookAuthProvider()
          break
        case Enums.loginFacets.GOOGLE:
          provider = new GoogleAuthProvider()
          break
      }

      // Then Perform Auth
      switch (facet) {
        case Enums.loginFacets.FACEBOOK:
        case Enums.loginFacets.GOOGLE:
          await signInWithRedirect(auth, provider)
          break
        default:
          setState({ facet })
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleCancelOption = async () => {
    setState({ facet: Enums.loginFacets.OPTIONS })
  }

  const handleLoginEmailLink = async (email) => {
    const facet = Enums.loginFacets.EMAIL_LINK_SUCCESS

    const actionCodeSettings = {
      url: window.location.origin,
      handleCodeInApp: true
    }

    try {
      setState({ disableForm: true })

      await sendSignInLinkToEmail(auth, email, actionCodeSettings)
      window.localStorage.setItem('emailForSignIn', email)

      setState({ facet, disableForm: false })
    } catch (e) {
      setState({ disableForm: false })
      alert(e.message)
    }
  }

  return (
    <>
      {!state.authError ? (
        <>
          <NavToolbar breadcrumbs={breadcrumbs} backTitle='Back to Home' backUrl='/' />

          {state.facet === Enums.loginFacets.OPTIONS ? (
            <LoginOptions loginType={state.loginType} onSelectOption={handleSelectOption} />
          ) : undefined}
          {state.facet === Enums.loginFacets.EMAIL_LINK ? (
            <LoginEmailLink
              loginType={state.loginType}
              onSubmit={handleLoginEmailLink}
              onCancelOption={handleCancelOption}
              disableForm={state.disableForm}
            />
          ) : undefined}
          {state.facet === Enums.loginFacets.EMAIL_LINK_SUCCESS ? (
            <LoginEmailSuccess loginType={state.loginType} />
          ) : undefined}
        </>
      ) : undefined}
    </>
  )
}

export default LoginUser
