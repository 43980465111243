/* eslint-disable no-loop-func */
import { initializeApp } from 'firebase/app'
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  connectFirestoreEmulator,
  addDoc,
  collection,
  getDocs,
  query,
  where
} from 'firebase/firestore'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import Enums from './enums'

// Initialize Firebase
const config = {
  apiKey: 'AIzaSyAIe0ud3U9fkZYzLrfRHLm2a7Cto1-hQrc',
  authDomain: 'metability-37dbb.firebaseapp.com',
  projectId: 'metability-37dbb',
  storageBucket: 'metability-37dbb.appspot.com',
  messagingSenderId: '199583059562',
  appId: '1:199583059562:web:0864b769339fdc1d794413',
  measurementId: 'G-MD4N21SRPN'
}

const isProd = process.env.REACT_APP_ENVIRONMENT === Enums.environment.FIREBASE_PROD
const app = initializeApp(config)
const db = getFirestore()
const auth = getAuth()

let analytics = null

// Init Firebase & Analytics if running on correct environment
if (isProd) {
  analytics = getAnalytics(app)
} else {
  connectFirestoreEmulator(db, 'localhost', 8080)
  connectAuthEmulator(auth, 'http://localhost:9099')
}

// FIREBASE FUNCTIONS
const fetchUserData = (uid) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let result = null

      try {
        const docRef = doc(db, 'userData', uid)
        const docSnap = await getDoc(docRef)

        if (docSnap.exists()) result = docSnap.data()
        resolve(result)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

const updateUserProfilePic = (uid, profilePicId) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const docRef = doc(db, 'userData', uid)
        await updateDoc(docRef, { profilePicId })

        resolve()
      } catch (e) {
        reject(e)
      }
    })()
  })
}

const updateUserData = (uid, data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const docRef = doc(db, 'userData', uid)
        await updateDoc(docRef, data)

        resolve()
      } catch (e) {
        reject(e)
      }
    })()
  })
}

const addUserData = (uid, userData) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        await setDoc(doc(db, 'userData', uid), userData)
        resolve()
      } catch (e) {
        reject(e)
      }
    })()
  })
}

const createTransactionLog = (data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        if (isProd) {
          await addDoc(collection(db, 'transactionLogs'), data)
        }

        resolve()
      } catch (e) {
        reject(e)
      }
    })()
  })
}

const logAnalyticsEvent = (eventType, data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        if (isProd) {
          logEvent(analytics, eventType, data)
        }

        resolve()
      } catch (e) {
        reject(e)
      }
    })()
  })
}

const getCourseDoc = (courseId, includeLevels, includeLessons) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let docRef = null
      let tmpData = null
      let tmpEntry = null
      let errMsg = null
      let key = null
      let course = null

      try {
        // First get Course Profile
        docRef = doc(db, 'courses', courseId)
        tmpData = await getDoc(docRef)

        if (tmpData.exists()) {
          course = tmpData.data()
          course.id = tmpData.id
        } else {
          errMsg = 'No Course found for id: - ' + courseId
          throw new Error(errMsg)
        }

        // Next, get Levels if relevant
        if (includeLevels) {
          course.levels = []
          key = `courses/${courseId}/levels`
          docRef = collection(db, key)
          tmpData = await getDocs(query(docRef))

          tmpData.forEach((item) => {
            tmpEntry = item.data()

            course.levels.push({
              id: item.id,
              label: tmpEntry.label,
              sortOrder: tmpEntry.sortOrder
            })
          })

          // Get Lessons for each Level, if relevant
          if (includeLessons) {
            for (const level of course.levels) {
              level.lessons = []
              key = `courses/${courseId}/lessons`
              docRef = collection(db, key)
              tmpData = await getDocs(query(docRef, where('levelId', '==', level.id)))

              tmpData.forEach((item) => {
                tmpEntry = item.data()

                // Fixup entry
                tmpEntry.id = item.id
                delete tmpEntry.createdAt
                delete tmpEntry.createdBy
                delete tmpEntry.levelId
                delete tmpEntry.modifiedAt
                delete tmpEntry.modifiedBy

                level.lessons.push(tmpEntry)
              })
            }
          }
        }

        resolve(course)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

const getCourseContent = (courseId, lessonId) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let docRef = null
      let tmpData = null
      let errMsg = null
      let key = null
      let lesson = null
      let content = null

      try {
        // First get Lesson
        key = `courses/${courseId}/lessons`
        docRef = doc(db, key, lessonId)
        tmpData = await getDoc(docRef)

        if (tmpData.exists()) {
          lesson = tmpData.data()
          lesson.id = tmpData.id
        } else {
          errMsg = 'No Lesson found for id: - ' + lessonId
          throw new Error(errMsg)
        }

        // Next, get Lesson content
        if (lesson.content) {
          key = `courses/${courseId}/content`
          docRef = doc(db, key, lesson.content)
          tmpData = await getDoc(docRef)

          if (tmpData.exists()) {
            content = tmpData.data()
            lesson.content = content.content
          } else {
            errMsg = 'No Content found for id: - ' + lesson.content
            throw new Error(errMsg)
          }
        }

        // Finalize result
        delete lesson.createdAt
        delete lesson.createdBy
        delete lesson.modifiedAt
        delete lesson.modifiedBy
        delete lesson.levelId

        resolve(lesson)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export {
  auth,
  fetchUserData,
  addUserData,
  updateUserProfilePic,
  updateUserData,
  createTransactionLog,
  logAnalyticsEvent,
  getCourseDoc,
  getCourseContent
}
