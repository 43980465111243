import React from 'react'
import cn from 'classnames'
import { Carousel, Col, Row } from 'antd'

import styles from './style.module.sass'

const CarouselCourses = ({ data, onCourseClick }) => {
  return (
    <>
      <div className={cn(styles.section)}>
        <div className={cn('container', styles.container)}>
          <div className={styles.wrapper}>
            <div className={styles.stage}>What's Happening On Metability</div>
            <Carousel
              autoplay={data.length > 1 ? true : false}
              autoplaySpeed={7000}
              dotPosition='right'
              dots={{ className: styles.dots }}
            >
              {data.map((item, index) => (
                <div
                  key={index}
                  className={cn('section-bg', styles.content)}
                  onClick={() => onCourseClick(item.id, item.catalogueType)}
                >
                  <Row>
                    <Col xs={9} lg={7} xl={6}>
                      <div className={styles.imageWrapper}>
                        {item.catalogueType === 'course' && item.image && item.image.data ? (
                          <img src={`data:image/png;base64,${item.image.data}`} alt={item.title} />
                        ) : undefined}
                        {item.catalogueType === 'course' && item.image && !item.image.data ? (
                          <img src={item.image} alt={item.title} />
                        ) : undefined}
                        {item.catalogueType === 'event' && item.logoUrl ? (
                          <img src={item.logoUrl} alt={item.title} />
                        ) : undefined}
                      </div>
                    </Col>
                    <Col xs={15} lg={17} xl={18} className={styles.dataWrapper}>
                      <Row>
                        <Col xs={24} style={{ display: 'flex' }}>
                          <div className={styles.catalogueType}>{item.catalogueType}</div>
                          {item.custom.announcement === 'In Progress' ? (
                            <div className={cn(styles.announcement, styles.announcementInProgress)}>
                              {`(${item.custom.announcement})`}
                            </div>
                          ) : undefined}
                          {item.custom.announcement === 'Coming Soon' ? (
                            <div className={cn(styles.announcement, styles.announcementComingSoon)}>
                              {`(${item.custom.announcement})`}
                            </div>
                          ) : undefined}
                          {item.custom.announcement === 'New Release' ? (
                            <div className={cn(styles.announcement, styles.announcementNewRelease)}>
                              {`(${item.custom.announcement})`}
                            </div>
                          ) : undefined}
                        </Col>
                        <Col xs={24}>
                          <div className={styles.title}>{item.title}</div>
                        </Col>
                        <Col xs={24}>
                          <div className={styles.icon}>
                            <img src={item.provider.logoUrl} alt={item.provider.name} />
                          </div>
                          <span className={styles.userLabel}>Platform: {item.provider.name}</span>
                        </Col>
                        <Col xs={24} className={styles.creatorWrapper}>
                          <div className={cn(styles.icon, styles.iconCreator)}>
                            <img src={item.creator.logoUrl} alt={item.creator.name} />
                          </div>
                          <span className={styles.userLabel}>Author: {item.creator.name}</span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </>
  )
}

export default CarouselCourses
