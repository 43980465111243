import React, { useEffect, useReducer } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import styles from './style.module.sass'
import Icon from 'lib/icons-svg'

const Item = ({ lesson, course, levelId, isLocked }) => {
  const userState = useSelector((state) => state.core.user.data.state)

  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    visible: false,
    levelId: '',
    claimed: false
  })

  useEffect(() => {
    function init() {
      try {
        // Collapse sections when level changes
        if (state.levelId !== levelId) setState({ visible: false, levelId })
      } catch (e) {
        console.error(e)
      }
    }

    init()
    // eslint-disable-next-line
  }, [levelId])

  // Manage state of lesson on load
  useEffect(() => {
    function init() {
      let userCourse = null
      let userLevel = null
      let userLesson = null
      let claimed = false

      try {
        userCourse = userState.courses[course.id]

        if (userCourse) {
          userLevel = userCourse.levels[levelId]

          if (userLevel) {
            userLesson = userLevel.lessons[lesson.id]
            if (userLesson) claimed = true
          }
        }

        setState({ claimed })
      } catch (e) {
        console.error(e)
      }
    }

    init()
    // eslint-disable-next-line
  }, [lesson.id])

  return (
    <div className={cn(styles.lesson, { [styles.active]: state.visible })}>
      <Link to={!isLocked ? `/courses/${course.id}/levels/${levelId}/lessons/${lesson.id}` : `/courses/${course.id}/`}>
        <Row>
          <Col xs={2} md={1} className={styles.claimed}>
            {state.claimed ? <Icon name='check' size='14' /> : null}
          </Col>
          <Col xs={20} md={21}>
            <div className={styles.head} onClick={() => setState({ visible: !state.visible })}>
              {lesson.title}
            </div>
            <div className={styles.body}>{lesson.description}</div>
          </Col>
          <Col xs={2} className={styles.iconWrapper}>
            <RightOutlined style={{ fontSize: 20, float: 'right', verticalAlign: 'middle' }} />
          </Col>
        </Row>
      </Link>
    </div>
  )
}

export default Item
