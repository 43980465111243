import React from 'react'
import cn from 'classnames'
import styles from './style.module.sass'

const QuizExitModalContent = ({ title, onBtnClickYes, onBtnClickNo }) => {
  return (
    <div>
      <div className={cn(styles.title)}>{title}</div>
      <div className={styles.btns}>
        <button className={cn('button-stroke', styles.buttonYes)} onClick={onBtnClickYes}>
          Yes
        </button>
        <button className={cn('button-stroke', styles.buttonNo)} onClick={onBtnClickNo}>
          No
        </button>
      </div>
    </div>
  )
}

export default QuizExitModalContent
