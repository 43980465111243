import React, { useState } from 'react'
import { Row, Col, message } from 'antd'
import cn from 'classnames'
import Validate from 'agilite-utils/validate-js'

import styles from './style.module.sass'

import TextInput from 'components/TextInput'
import Icon from 'lib/icons-svg'
import ButtonSpinner from 'components/ButtonSpinner'

const LoginEmailLink = ({ loginType, onSubmit, onCancelOption, disableForm }) => {
  const [email, setEmail] = useState('')

  const text1 = loginType === 'register' ? 'Sign Up' : 'Sign In'
  const text2 = loginType === 'register' ? 'sign up' : 'sign in'

  const handleOnChange = (key, value) => {
    try {
      switch (key) {
        case 'email':
          setEmail(value.toLowerCase())
          break
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleSubmit = async () => {
    let errMsg = null
    let constraints = null
    let validateMsg = null

    try {
      // Validate Input fields
      if (!email) {
        errMsg = 'Please provide an Email address'
      } else if (errMsg) throw new Error(errMsg)

      // Check if Email is valid
      constraints = {
        username: {
          email: true
        }
      }

      validateMsg = Validate({ username: email }, constraints)

      if (validateMsg) {
        errMsg = 'The Email address provided is not valid. Please revise'
        throw new Error(errMsg)
      }

      onSubmit(email)
    } catch (e) {
      message.error({
        content: e.message,
        className: 'message-prompt'
      })
    }
  }

  return (
    <div className='section-pt20'>
      <div className={styles.top}>
        <h5 className={cn('h5', styles.title)}>{`${text1} using an Email Link`}</h5>
      </div>
      <Row justify='center'>
        <Col xs={22} sm={22} md={12} lg={10} xl={6}>
          <div className={styles.note}>
            {`Provide your email below & click on 'Submit' to send an auto ${text2} link to your Mailbox.`}
          </div>
        </Col>
      </Row>
      <Row justify='center'>
        <Col xs={20} md={10} lg={8} xl={6}>
          <TextInput
            className={styles.field}
            name='email'
            type='email'
            placeholder='Email Address'
            value={email}
            required
            onChange={(e) => handleOnChange(e.target.name, e.target.value)}
            disabled={disableForm}
          />
        </Col>
      </Row>
      <Row justify='center'>
        <Col>
          <div className={styles.btns}>
            <button
              className={cn('button', styles.button, disableForm ? styles.buttonDisabled : undefined)}
              onClick={handleSubmit}
              disabled={disableForm}
            >
              <ButtonSpinner spinning={disableForm} />
              Submit
            </button>
            {!disableForm ? (
              <button className={styles.clear} onClick={onCancelOption}>
                <Icon name='circle-close' size='24' />
                Cancel
              </button>
            ) : undefined}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default LoginEmailLink
