import { createSlice } from '@reduxjs/toolkit'
import { getQuizDataTemplate } from 'lib/templates'

const initialState = getQuizDataTemplate()

export const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    setQuizData: (state, action) => {
      state.updatedAt = action.payload.updatedAt
      state.isActive = action.payload.isActive
      state.gameData = action.payload.gameData
      state.userData = action.payload.userData
      state.cardTypes = action.payload.cardTypes
      state.gameCards = action.payload.gameCards
      state.flow = action.payload.flow
    },
    setUserData: (state, action) => {
      state.updatedAt = action.payload.updatedAt
      state.userData = action.payload.data
    },
    setGameData: (state, action) => {
      state.updatedAt = action.payload.updatedAt
      state.gameData = action.payload.data
    },
    setActionPoints: (state, action) => {
      state.updatedAt = action.payload.updatedAt
      state.gameData.actionPointsAvailable = action.payload.data.actionPointsAvailable
      state.userData.actionPointsUsed = action.payload.data.actionPointsUsed
    },
    setQuestions: (state, action) => {
      state.questions = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setQuizData, setUserData, setGameData, setActionPoints, setQuestions } = quizSlice.actions
export default quizSlice.reducer
