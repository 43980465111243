import React, { useEffect, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { Col, Divider, Row } from 'antd'

import styles from './style.module.sass'

import Item from './Item'
import Dropdown from 'components/Dropdown'
import LoadingIndicator from 'components/LoadingIndicator'
import { setCourseData } from '-core/core-reducer'

const Content = ({ parentState }) => {
  const dispatch = useDispatch()
  const { course, levelOptions } = parentState
  const courseData = useSelector((state) => state.core.course)

  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    initialized: false,
    activeLevel: {},
    levelId: '',
    levelLessons: []
  })

  useEffect(() => {
    function init() {
      let errMsg = null
      let tmpLevel = null
      let tmpSelectedCourseLevel = null

      try {
        // Check if our variables have been processed
        if (!parentState.initialized) return

        tmpSelectedCourseLevel = courseData.selectedCourseLevel || levelOptions[0]
        tmpLevel = course.levels.find((item) => item.label === tmpSelectedCourseLevel)

        if (!tmpLevel) {
          errMsg = `No Lessons found for Course/Level: ${course.id} - ${tmpSelectedCourseLevel}`
          throw new Error(errMsg)
        }

        setActiveLevelLabel(tmpSelectedCourseLevel)

        setState({
          initialized: true,
          activeLevel: tmpLevel,
          levelId: tmpLevel.id,
          levelLessons: tmpLevel.lessons
        })
      } catch (e) {
        console.error(e)
      }
    }

    init()
    // eslint-disable-next-line
  }, [parentState.initialized, courseData.selectedCourseLevel])

  const setActiveLevelLabel = (label) => {
    const tmpCourseData = JSON.parse(JSON.stringify(courseData))
    tmpCourseData.selectedCourseLevel = label
    dispatch(setCourseData(tmpCourseData))
  }

  // const setSelectedTab = (key) => {
  //   const tmpCourseData = JSON.parse(JSON.stringify(courseData))
  //   tmpCourseData.selectedTab = key
  //   dispatch(setCourseData(tmpCourseData))
  // }

  return (
    <>
      {!parentState.initialized || !state.initialized ? (
        <LoadingIndicator />
      ) : (
        <div className='section'>
          <div className='container'>
            <Row className={styles.top}>
              <Col xs={24} md={{ span: 16, offset: 4 }} lg={{ span: 24, offset: 0 }}>
                <h1 className={cn('h4', styles.title)}>{course.title}</h1>
                <div className={styles.info}>{course.description}</div>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Row className={styles.dropdownWrapper}>
                  <Col xs={24} md={{ span: 12, offset: 6 }}>
                    <Dropdown
                      value={courseData.selectedCourseLevel}
                      setValue={setActiveLevelLabel}
                      options={levelOptions}
                    />
                  </Col>
                </Row>
                <Row className={styles.row}>
                  <Col className={styles.levelColumn} lg={8}>
                    {course.levels.map((item, index) => (
                      <div key={index}>
                        <div
                          className={cn(styles.link, {
                            [styles.active]: item.label === courseData.selectedCourseLevel
                          })}
                          onClick={() => setActiveLevelLabel(item.label)}
                        >
                          <span>{item.label}</span>
                        </div>
                        <Divider className={styles.divider} style={{ margin: 0 }} />
                      </div>
                    ))}
                  </Col>
                  <Col xs={24} md={{ span: 20, offset: 2 }} lg={{ span: 15, offset: 1 }}>
                    {state.levelLessons.map((item, index) => (
                      <div key={index}>
                        <Item
                          lesson={item}
                          isLocked={state.activeLevel.isLocked}
                          course={course}
                          levelId={state.levelId}
                        />
                        <Divider className={styles.divider} style={{ margin: 0 }} />
                      </div>
                    ))}
                    {/* {state.activeLevel.quizEnabled ? (
                      <Link
                        className={cn('button', styles.button)}
                        to={`/academy/${course.category}/${course.id}/${state.levelId}/quiz`}
                      >
                        <img src='/images/icons/quiz-icon-100.png' alt='Quiz' style={{ width: 20 }} /> &nbsp;Start Quiz
                      </Link>
                    ) : undefined}
                    {state.activeLevel.quizComplete ? (
                      <Link
                        className={cn('button', styles.button)}
                        to={`/academy/${course.category}/${course.id}/${state.levelId}/quiz`}
                      >
                        <img src='/images/icons/academy-icon-100.png' alt='Review' style={{ width: 20 }} /> &nbsp;Quiz
                        Review
                      </Link>
                    ) : undefined} */}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  )
}

export default Content
