import React, { useEffect, useReducer } from 'react'
import cn from 'classnames'
import Slider from 'react-slick'

import styles from './styles.module.sass'
import Icon from 'lib/icons-svg'
import Add from './Add'
// import Dropdown from 'components/Dropdown'

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => <button {...props}>{children}</button>

const sortOptionsListing = ['Most Recent', 'Newest', 'Oldest']
const signImage = '/images/content/donut.svg'
const nftIcon = '/images/nft-icon.svg'

const NFTGallery = ({ items, onAddRemoveCard }) => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: (
      <SlickArrow>
        <Icon name='arrow-next' size='14' />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name='arrow-prev' size='14' />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 1340,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          infinite: true
        }
      }
    ]
  }

  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    sortOptions: sortOptionsListing[0],
    cardGallery: []
  })

  // Init Component Once parent has completed tasks
  useEffect(() => {
    function init() {
      let cardGallery = []
      let tmpArray = null

      try {
        if (items && items.length > 0) {
          cardGallery = JSON.parse(JSON.stringify(items))

          // Process additional props for card
          for (const item of cardGallery) {
            tmpArray = []

            for (const effect of item.effects) {
              tmpArray.push(effect.description)
            }

            item.effectsDescription = tmpArray.join(', ')

            // For now, we only make advanced cards NFTs
            if (item.rarity === 'advanced') item.isNFT = true
          }

          setState({ cardGallery })
        }
      } catch (e) {
        console.error(e)
      }
    }

    init()
    // eslint-disable-next-line
  }, [items])

  return (
    <div className={cn('section-bg', styles.section)}>
      <center>
        <div className={styles.title}>Card Gallery</div>
      </center>
      <div className={cn('container', styles.container)}>
        {/* <div className={styles.top}>
          <div className={styles.field}>
            <div className={styles.label}>Sort Gallery By</div>
            <Dropdown
              className={styles.dropdown}
              value={state.sortOptions}
              stateProp='sortOptions'
              setValue={setState}
              options={sortOptionsListing}
            />
          </div>
        </div> */}
        <div className={styles.wrapper}>
          <Slider className='popular-slider' {...settings}>
            {state.cardGallery.map((item, index) => (
              <div className={cn(styles.card, styles[`rarity-${item.rarity}`])} key={index}>
                <div className={styles.item}>
                  <div className={styles.head}>
                    <div className={cn(styles.actionPoints)} style={{ backgroundColor: '#23262F' }}>
                      <div className={styles.icon}>
                        <img src={signImage} alt={'Action Points'} />
                      </div>
                      <div>{item.actionPoints} AP</div>
                    </div>
                    <div className={styles.control}>
                      <Add item={item} onAddRemoveCard={onAddRemoveCard} />
                    </div>
                  </div>
                  <div className={styles.body}>
                    <div className={styles.avatar}>
                      <img src={item.imageUrl} alt={item.description} />
                      {item.isNFT ? (
                        <div className={styles.nft}>
                          <img src={nftIcon} width={25} alt='Verified NFT' />
                        </div>
                      ) : undefined}
                    </div>
                    <div className={styles.name}>{item.name}</div>
                    <div className={styles.effects} dangerouslySetInnerHTML={{ __html: item.effectsDescription }} />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default NFTGallery
