import React, { useEffect, useReducer } from 'react'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams, useLocation } from 'react-router-dom'
import { Col, Row, List } from 'antd'
import useDarkMode from 'use-dark-mode'

import styles from './style.module.sass'

import NavToolbar from 'components/NavToolbar'
import { setWrapper } from '-core/core-reducer'
import { getLogTemplate } from 'lib/templates'
import { createTransactionLog, logAnalyticsEvent } from 'lib/firebase'

const CourseOverview = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const params = useParams()
  const darkMode = useDarkMode(false)

  const reduxAllCourses = useSelector((state) => state.core.courseCatalogue.all.data)
  const userId = useSelector((state) => state.core.user.uid)

  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    data: {},
    initialized: false
  })

  const breadcrumbs = [
    {
      title: 'Home',
      url: '/'
    },
    {
      title: 'Course Overview'
    }
  ]

  // Runs on load of component
  useEffect(() => {
    function initComponent() {
      let data = null
      let hasUrlParam = false

      try {
        dispatch(setWrapper({ headerEnabled: true, footerEnabled: true }))

        // Populate Course Data
        data = reduxAllCourses.find((item) => item.id === params.courseId)
        data = JSON.parse(JSON.stringify(data))

        // Prep Course URL
        if (data.provider.enableUTM) {
          hasUrlParam = true
          data.courseUrl +=
            '?utm_content=go_to_course_button&utm_medium=course_overview_page&utm_source=metability.io&utm_campaign=general'
        }

        if (data.provider.referralLink) {
          if (!hasUrlParam) {
            data.courseUrl += '?'
          } else {
            data.courseUrl += '&'
          }

          data.courseUrl += data.provider.referralLink
        }

        setState({ initialized: true, data })
      } catch (e) {
        console.error(e)
      }
    }

    initComponent()
    // eslint-disable-next-line
  }, [])

  const handleGoToCourse = async () => {
    try {
      const entry = getLogTemplate(userId, darkMode.value)
      entry.path = pathname
      entry.code = 'trigger_go_to_course'
      await createTransactionLog(entry)
      await logAnalyticsEvent(entry.code, JSON.stringify(entry))
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <NavToolbar breadcrumbs={breadcrumbs} backTitle='Back to Home' backUrl='/' />
      {state.initialized ? (
        <Row>
          <Col xs={24} className={cn('section-bg', styles.content)}>
            <Row>
              <Col xs={24} className={styles.titleWrapper}>
                {state.data.title}
              </Col>
              <Col xs={24} lg={9}>
                <center>
                  <div className={styles.imageWrapper}>
                    {state.data.image && state.data.image.data ? (
                      <img src={`data:image/png;base64,${state.data.image.data}`} alt={state.data.title} />
                    ) : undefined}
                    {state.data.image && !state.data.image.data ? (
                      <img src={state.data.image} alt={state.data.title} />
                    ) : undefined}
                  </div>
                </center>
              </Col>
              <Col xs={24} lg={15} className={styles.detailWrapper}>
                <Row>
                  <Col xs={24}>
                    <div className={styles.icon}>
                      <img src={state.data.provider.logoUrl} alt={state.data.provider.name} />
                    </div>
                    <div className={styles.labels}>
                      Platform: <span>{state.data.provider.name}</span>
                    </div>
                  </Col>
                  <Col xs={24} style={{ marginTop: 10 }}>
                    <div className={cn(styles.icon, styles.iconCreator)}>
                      <img src={state.data.creator.logoUrl} alt={state.data.creator.name} />
                    </div>
                    <div className={styles.labels}>
                      Author: <span>{state.data.creator.name}</span>
                    </div>
                  </Col>
                  <Col xs={24}>
                    <div className={styles.priceWrapper}>
                      <span>Price: </span>
                      <span className={cn(styles[state.data.priceClass])}>{state.data.price}</span>
                    </div>
                  </Col>
                  <Col xs={24}>
                    <div className={styles.descriptionWrapper}>{state.data.description}</div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <List
                  header={<div className={styles.courseBreakdown}>Course Breakdown</div>}
                  dataSource={state.data.sections}
                  renderItem={(item) => (
                    <List.Item>
                      <div className={styles.listItem}>{item.title}</div>
                    </List.Item>
                  )}
                />
              </Col>
              <Col xs={24} className={styles.footer}>
                {state.data.platform === 'metability' ? (
                  <Link className={cn('button', styles.button)} to={state.data.courseUrl}>
                    <img
                      src={state.data.provider.logoUrl}
                      alt='Go To Course'
                      style={{ width: 30, marginLeft: -15, marginRight: 5 }}
                    />
                    Go To Course
                  </Link>
                ) : (
                  <a
                    className={cn('button', styles.button)}
                    onClick={handleGoToCourse}
                    href={state.data.courseUrl}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img
                      src={state.data.provider.logoUrl}
                      alt='Go To Course'
                      style={{ width: 30, marginLeft: -15, marginRight: 5 }}
                    />
                    Go To Course
                  </a>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      ) : undefined}
    </>
  )
}

export default CourseOverview
