export const getCoreStateTemplate = () => {
  return {
    user: {
      uid: '',
      authComplete: false,
      data: {}
    },
    headerEnabled: true,
    footerEnabled: true,
    backUrl: '',
    web3: {
      isMetaMask: false,
      isWeb3Enabled: false,
      ethAddress: '',
      chain: {
        id: '',
        name: '',
        shortName: '',
        networkId: 0,
        currency: ''
      }
    },
    course: {
      selectedTab: '1',
      selectedCourseLevel: ''
    },
    courseCatalogue: {
      initialized: false,
      all: {
        title: 'All Courses',
        data: []
      },
      featured: {
        title: 'Featured Courses',
        data: []
      },
      recent: {
        title: 'Recently Added',
        data: []
      },
      newbies: {
        title: 'New to Web3? Get started here',
        data: []
      },
      nft: {
        title: 'Learn about NFTs',
        data: []
      },
      buildspace: {
        title: 'Courses By Buildspace',
        data: []
      },
      metaschool: {
        title: 'Courses By Metaschool',
        data: []
      },
      metability: {
        title: 'Courses By Metability',
        data: []
      }
    },
    events: {
      initialized: false,
      data: []
    }
  }
}

export const getUserTemplate = () => {
  return {
    firstName: '',
    lastName: '',
    username: '',
    profilePicId: '',
    profileComplete: false,
    state: {
      general: {
        ethAddress: '',
        nftAwards: [],
        events: [],
        eventIds: [],
        initialized: false,
        skillPoints: 0,
        skillCoins: 0,
        skillLevel: 1
      },
      courses: {}
    }
  }
}

export const getUserCourseTemplate = () => {
  return {
    status: 'Active',
    skillPoints: 0,
    skillCoins: 0,
    levels: {}
  }
}

export const getUserLevelTemplate = () => {
  return {
    lessonsCompleted: false,
    quizComplete: false,
    skillPoints: 0,
    skillCoins: 0,
    lessons: {}
  }
}

export const getUserLessonTemplate = () => {
  return {
    skillPoints: 0,
    skillCoins: 0
  }
}

export const getQuizDataTemplate = () => {
  return {
    updatedAt: Date.now(),
    isActive: false,
    gameData: {
      stepKey: null,
      stepName: '',
      optionKey: null,
      optionName: '',
      timeStart: null,
      timeEnd: null,
      actionPointsForTurn: 0,
      actionPointsAvailable: 0,
      actionButtonTitle: '',
      turn: 1, // The rest of the props come down from Agilit-e BPM
      turnsTotal: 0,
      phaseStartCountdownSeconds: 0,
      quizTimeSeconds: 0,
      coinsPerSec: 0,
      percentPassRequired: 0,
      mininumQuestionsRequired: 0, // TODO: Armand - Include in Prep Phase Screen and Incorporate into Quiz Phase
      actionPointsStart: 0,
      actionPointsIncrement: 0,
      resourceMinimum: 0
    },
    userData: {
      actionPointsUsed: 0,
      skillPoints: 0,
      skillCoins: 0,
      resourceCards: [],
      actionCards: [],
      cardDeck: []
    },
    questions: [],
    flow: {}
  }
}

export const getCardDeckDataTemplate = () => {
  return {
    name: '',
    description: ''
  }
}

export const getQuizInitialState = (isDarkMode) => {
  return {
    statusClass: '',
    isStarted: false,
    timeoutEnabled: false,
    displayResult: false,
    resultTitle: '',
    resultStatus: '',
    progress: 100,
    progressColor: 'green',
    trailColor: isDarkMode ? '#000000' : '#FFFFFF',
    prevQuestionId: '',
    questionId: '',
    question: '',
    answers: []
  }
}

export const getQuizNextQuestionState = (questionId, question, answers) => {
  return {
    timeoutEnabled: false,
    displayResult: false,
    progress: 100,
    progressColor: 'green',
    resultTitle: '',
    resultStatus: '',
    questionId,
    question,
    answers
  }
}

export const getQuizStopTimerState = (resultTitle, resultStatus) => {
  return {
    timeoutEnabled: false,
    displayResult: true,
    progress: 100,
    progressColor: 'green',
    resultTitle,
    resultStatus
  }
}

export const getLogTemplate = (userId, isDarkMode = false) => {
  let result = null

  try {
    result = {
      updatedAt: new Date().toISOString(),
      userId,
      message: '',
      path: '',
      code: '',
      data: {
        isDarkMode,
        appVersion: process.env.REACT_APP_VERSION
      }
    }

    return result
  } catch (e) {
    console.error(e)
  }
}
