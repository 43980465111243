import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import cn from 'classnames'

import styles from './style.module.sass'

import Image from 'components/Image'
// import Notification from './Notification'
import User from './User'
import Icon from 'lib/icons-svg'

const Headers = () => {
  const history = useHistory()
  const reduxUser = useSelector((state) => state.core.user)
  const backUrl = useSelector((state) => state.core.backUrl)
  const isMetaMask = useSelector((state) => state.core.web3.isMetaMask)
  const isWeb3Enabled = useSelector((state) => state.core.web3.isWeb3Enabled)
  const [visibleNav, setVisibleNav] = useState(false)

  // Setup Toolbar Nav
  const nav = [
    {
      url: '/',
      title: 'Home'
    },
    // {
    //   url: '/about',
    //   title: 'About'
    // },
    {
      url: '/howitworks',
      title: 'How It Works'
    }
    // {
    //   url: '/gettingstarted',
    //   title: 'Getting Started'
    // },
    // {
    //   title: 'Join The Alpha Program',
    //   url: '/alphaprogram'
    // }
    // {
    //   url: '/faq',
    //   title: 'FAQ'
    // }
  ]

  // if (userData.objectId) {
  //   nav.push({
  //     url: '/comingsoon',
  //     title: 'User Profile'
  //   })
  // }

  const handleMenuItemClick = (url) => {
    history.replace(url)
    setVisibleNav(false)
  }

  return (
    <header className={styles.header}>
      <div className={cn('container', styles.container)}>
        {backUrl ? (
          <Link className={cn('button-stroke', styles.buttonBack)} to={backUrl}>
            <Icon name='arrow-prev' size='16' />
          </Link>
        ) : undefined}
        <Link className={styles.logo} to='/'>
          <Image
            src='/images/metability/metability-generic-logo-only-square-185x150-beta.png'
            srcDark='/images/metability/metability-generic-logo-only-square-185x150-beta.png'
            alt='Metability'
          />
        </Link>
        {!isMetaMask || !isWeb3Enabled ? (
          <Image
            className={styles.metamaskIcon}
            src='/images/icons/metamask-not-connected-icon-400.png'
            srcDark='/images/icons/metamask-not-connected-icon-400.png'
            alt='Not Connected To Blockchain'
          />
        ) : (
          <Image
            className={styles.metamaskIcon}
            src='/images/icons/metamask-connected-icon-400.png'
            srcDark='/images/icons/metamask-connected-icon-400.png'
            alt='Connected To Blockchain'
          />
        )}
        <div className={cn(styles.wrapper, { [styles.active]: visibleNav })}>
          <nav className={styles.nav}>
            {nav.map((x, index) => (
              <button onClick={() => handleMenuItemClick(x.url)} className={styles.link} key={index}>
                {x.title}
              </button>
            ))}
          </nav>
        </div>
        {reduxUser.uid ? (
          <div className={styles.userWrapper} style={backUrl ? { right: 63 } : undefined}>
            <User backUrl={backUrl} />
          </div>
        ) : undefined}
        <button
          className={cn(styles.burger, { [styles.active]: visibleNav })}
          onClick={() => setVisibleNav(!visibleNav)}
        ></button>
      </div>
    </header>
  )
}

export default Headers
