import React from 'react'
import cn from 'classnames'

import styles from './style.module.sass'

import User from './User'
import NavToolbar from 'components/NavToolbar'

const Profile = () => {
  const breadcrumbs = [
    {
      title: 'Home',
      url: '/'
    },
    {
      title: 'My Profile',
      url: '/profile'
    }
  ]

  return (
    <div>
      <NavToolbar breadcrumbs={breadcrumbs} backTitle='Back to Home' backUrl='/' />
      <div
        className={cn(styles.head)}
        style={{
          backgroundImage: 'url(/images/background-profile.jpg)'
        }}
      />
      <center>
        <div className={styles.body}>
          <User />
        </div>
      </center>
    </div>
  )
}

export default Profile
