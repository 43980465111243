import React, { useEffect, useReducer } from 'react'
import { Col, Row, Carousel, Tag } from 'antd'
import cn from 'classnames'
import Icon from 'lib/icons-svg'
import ButtonSpinner from 'components/ButtonSpinner'

import styles from './style.module.sass'

const SlideDeck = ({ children, claimProps, slideDeckKey }) => {
  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    slide: null
  })

  const isTouchDevice = window.navigator.maxTouchPoints

  useEffect(() => {
    setState({ slide: 1 })

    return () => {
      setState({ slide: 1 })
    }
  }, [children.length, slideDeckKey])

  return (
    <>
      <Row className={cn('container', styles.container)} justify='center'>
        <Col xs={24} md={20} lg={16}>
          <div className={cn(styles.card)}>
            <Row justify='space-around' className={styles.cardHeader}>
              <Col>
                <Tag color='magenta' style={{ borderRadius: 15 }}>
                  {isTouchDevice ? 'Swipe to Navigate' : 'Use Arrows to Navigate'}
                </Tag>
              </Col>
              {claimProps?.claimed ? (
                <Col>
                  <Tag color='orange' style={{ borderRadius: 15 }}>
                    claimed
                  </Tag>
                </Col>
              ) : null}
              <Col>
                <Tag color='geekblue' style={{ borderRadius: 15 }}>
                  Slide {state.slide} of {children.length}
                </Tag>
              </Col>
            </Row>
            <Row className={styles.cardContent}>
              <Col xs={24} className={styles.carouselWrapper}>
                <Carousel
                  key={slideDeckKey}
                  className={styles.carousel}
                  dots={{ className: styles.dots }}
                  adaptiveHeight
                  arrows={true}
                  afterChange={(current) => {
                    setState({ slide: current + 1 })
                  }}
                  effect='scrollx'
                  nextArrow={
                    <span>
                      <Icon name='arrow-next' size='19' className={styles.next} />
                    </span>
                  }
                  prevArrow={
                    <span>
                      <Icon name='arrow-prev' size='19' className={styles.prev} />
                    </span>
                  }
                >
                  {children}
                </Carousel>
              </Col>
            </Row>
            <Row justify='center' className={styles.cardFooter}>
              <Col>
                {claimProps ? (
                  <Col>
                    {claimProps?.claimed ? (
                      <button
                        className={cn('button-stroke button-small', styles.btnNextLesson)}
                        onClick={() => {
                          claimProps.onBtnClickNext()
                        }}
                        hidden={!claimProps.nextLesson}
                      >
                        Next Lesson
                      </button>
                    ) : (
                      <button
                        className={cn(
                          'button-stroke button-small',
                          claimProps.disableForm || claimProps.claimed || state.slide !== children.length
                            ? styles.buttonDisabled
                            : styles.buttonClaim
                        )}
                        onClick={claimProps.handleClaim}
                        disabled={claimProps.disableForm || claimProps.claimed || state.slide !== children.length}
                      >
                        <ButtonSpinner spinning={claimProps.disableForm} />
                        {!claimProps.claimed ? (
                          <img
                            src='/images/icons/skill-points-icon-100.png'
                            alt='Claim'
                            style={{ width: 30, marginRight: 5 }}
                          />
                        ) : undefined}
                        {claimProps.btnClaimLabel}
                      </button>
                    )}
                  </Col>
                ) : null}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default SlideDeck
