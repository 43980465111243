import React from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import { Result } from 'antd'
import styles from './style.module.sass'

import Icon from 'lib/icons-svg'

const LoginEmailSuccess = () => {
  const returnResultTitle = () => {
    return <h2 className={cn('h2', styles.title)}>Email Link Request Successful</h2>
  }

  const returnResultSubTitle = () => {
    return <div className={styles.text}>You should receive an Email Verification shortly. Please check your inbox.</div>
  }

  return (
    <Result
      status='success'
      title={returnResultTitle()}
      subTitle={returnResultSubTitle()}
      extra={[
        <Link key='close' className={cn('button', styles.button)} to='/'>
          <Icon name='home' size='24' />
          Back to Home
        </Link>
      ]}
    />
  )
}

export default LoginEmailSuccess
