import React from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'

import styles from './style.module.sass'

import Group from './Group'
import Image from '../Image'
// TODO: import Form from '../Form'
import Theme from '../Theme'

const items = [
  {
    title: 'Navigation',
    menu: [
      {
        title: 'Home',
        url: '/'
      },
      // {
      //   title: 'About',
      //   url: '/about'
      // },
      {
        title: 'How It Works',
        url: '/howitworks'
      }
      // {
      //   title: 'Getting Started',
      //   url: '/gettingstarted'
      // },
      // {
      //   title: 'Join The Alpha Program',
      //   url: '/alphaprogram'
      // }
      // {
      //   title: 'FAQ',
      //   url: '/faq'
      // }
    ]
  },
  {
    title: 'Social Media',
    menu: [
      {
        title: 'Discord',
        url: 'https://discord.gg/pybQ7puTRH'
      },
      {
        title: 'YouTube',
        url: 'https://www.youtube.com/channel/UCpp1y0owx8MfNsfFeLVI9nw'
      },
      {
        title: 'Twitter',
        url: 'https://twitter.com/MetabilityApp'
      },
      {
        title: 'Facebook',
        url: 'https://www.facebook.com/Metability-106468505272582'
      },
      {
        title: 'Instagram',
        url: 'https://www.instagram.com/metabilityapp'
      },
      {
        title: 'TikTok',
        url: 'https://www.tiktok.com/@metabilityapp'
      }
    ]
  }
]

const Footers = () => {
  // TODO: const [email, setEmail] = useState('')

  // TODO: const handleSubmit = (e) => {
  //   alert()
  // }

  return (
    <footer className={styles.footer}>
      <div className={cn('container', styles.container)}>
        <div className={styles.row}>
          <div className={styles.col}>
            <Link className={styles.logo} to='/'>
              <Image
                className={styles.pic}
                src='/images/metability/metability-generic-banner-369x150-beta.png'
                srcDark='/images/metability/metability-generic-banner-369x150-beta.png'
                alt='Metability'
              />
            </Link>
            <div className={styles.info}>A Web3 Learn-To-Earn Platform</div>
            <div className={styles.version}>
              <div className={styles.details}>Dark theme</div>
              <Theme className='theme-big' />
            </div>
          </div>
          <div className={styles.col}>
            {items.map((x, index) => (
              <Group className={styles.group} item={x} key={index} />
            ))}
          </div>
          <div className={styles.col}>
            {/* TODO: */}
            {/* <div className={styles.category}>Join Newsletter</div>
            <div className={styles.text}>
              Subscribe our newsletter to stay up to date on courses, competitions and much more.
            </div>
            <Form
              className={styles.form}
              value={email}
              setValue={setEmail}
              onSubmit={() => handleSubmit()}
              placeholder='Enter your email'
              type='email'
              name='email'
            /> */}
          </div>
        </div>
        <div className={styles.foot}>
          <div className={styles.copyright}>Copyright © 2022 Metability. All rights reserved</div>
        </div>
      </div>
    </footer>
  )
}

export default Footers
