import React, { useEffect, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Anonymous from './Anonymous'
import Authed from './Authed'
import { setWrapper } from '-core/core-reducer'
import { signOut } from 'firebase/auth'
import { auth } from 'lib/firebase'

const Home = () => {
  const dispatch = useDispatch()
  const reduxUser = useSelector((state) => state.core.user)

  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    disableForm: false
  })

  // Runs on load of component
  useEffect(() => {
    dispatch(setWrapper({ headerEnabled: true, footerEnabled: true }))
    // eslint-disable-next-line
  }, [])

  const handleLogout = async () => {
    try {
      setState({ disableForm: true })
      await signOut(auth)
    } catch (e) {
      console.error(e)
    }

    setState({ disableForm: false })
  }

  return <>{reduxUser.uid ? <Authed onLogout={handleLogout} disableForm={state.disableForm} /> : <Anonymous />}</>
}

export default Home
