import React from 'react'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

import './style.css'

const CountdownTimer = (props) => {
  const renderTimer = ({ remainingTime }) => {
    return (
      <div className='timer'>
        <div className='text'>Remaining</div>
        <div className='value'>{remainingTime}</div>
        <div className='text'>seconds</div>
      </div>
    )
  }

  return (
    <div className='timer-wrapper center-both'>
      <CountdownCircleTimer
        isPlaying
        duration={props.duration}
        colors={['#004777', '#A30000', '#A30000']}
        colorsTime={[3, 1, 0]}
        onComplete={props.onTimerComplete}
      >
        {renderTimer}
      </CountdownCircleTimer>
    </div>
  )
}

export default CountdownTimer
