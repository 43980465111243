import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import cn from 'classnames'
import { Col, Row } from 'antd'

import styles from './style.module.sass'

import NFTGallery from './NFTGallery'
import { canCardActionOccur, updateActionPoints } from 'lib/utils'
import { setActionPoints, setUserData } from '../-main/-reducer'

// const sortOptions = ['Most Recent (disabled)']

const PrepPhase = ({ onNextPhase, course, level }) => {
  const dispatch = useDispatch()

  const gameData = useSelector((state) => state.quiz.gameData)
  const userData = useSelector((state) => state.quiz.userData)

  // const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
  //   initialized: false,
  //   category: 'All',
  //   categoryId: 'all',
  //   sort: sortOptions[0],
  //   subHeader: gameData.stepName,
  //   labelAPAvailable: `AP Available: ${gameData.actionPointsAvailable}`
  // })

  const handleAddRemoveCard = async (card, actionType) => {
    let dispatchData = null
    let userDataMutable = null
    let errMsg = false
    let actionPoints = null
    let apAction = null
    let updatedActionPoints = null
    let updatedAt = null
    let tmpCard = null

    try {
      // Determine the Card Action and the Card Placement
      // Then, move the card to the relevant section
      userDataMutable = JSON.parse(JSON.stringify(userData))
      tmpCard = userDataMutable.cardDeck.find((entry) => entry.gameCardId === card.gameCardId)
      if (!tmpCard) throw new Error(`No Card found in Card Deck for ID: ${card.gameCardId}`)

      switch (actionType) {
        case 'add':
          errMsg = await canCardActionOccur(tmpCard, gameData)
          if (errMsg) return alert(errMsg)

          tmpCard.isCardAdded = true
          actionPoints = tmpCard.actionPoints
          apAction = 'subtract'

          break
        case 'remove':
          tmpCard.isCardAdded = false
          actionPoints = tmpCard.actionPoints
          apAction = 'add'

          break
      }

      updatedAt = Date.now()

      // Update User Data
      dispatchData = { updatedAt, data: userDataMutable }
      dispatch(setUserData(dispatchData))

      // Updated Game Data
      updatedActionPoints = await updateActionPoints(actionPoints, apAction, gameData, userDataMutable)
      dispatchData = { updatedAt, data: updatedActionPoints }
      dispatch(setActionPoints(dispatchData))
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div>
      <div className={styles.top}>
        <Row>
          <Col xs={24} md={{ span: 9, offset: 3 }}>
            <div className={styles.title}>{`${course.title} - ${level.title}`}</div>
          </Col>
          <Col xs={24} md={{ span: 9, offset: 3 }}>
            <div className={styles.title}>{gameData.stepName}</div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col xs={12} md={{ span: 9, offset: 3 }}>
          <div className={styles.label}>{`Pass Required: ${gameData.percentPassRequired}%`}</div>
        </Col>
        <Col xs={12} md={{ span: 9, offset: 3 }}>
          {userData.cardDeck.length > 0 ? (
            <div className={cn(styles.label, styles.addedCards)}>Assigned Cards:</div>
          ) : undefined}
          {userData.cardDeck.map((item, index) =>
            item.isCardAdded ? (
              <img key={index} className={styles.addedCardImage} src={item.imageUrl} alt={item.name} />
            ) : undefined
          )}
        </Col>
        <Col xs={12} md={{ span: 9, offset: 3 }}>
          <div className={styles.label}>{`Minimum Answers Required: ${gameData.mininumQuestionsRequired}`}</div>
        </Col>
        <Col xs={12} md={{ span: 9, offset: 3 }}>
          {userData.cardDeck.length > 0 ? (
            <div className={styles.label}>{`Action Points Available: ${gameData.actionPointsAvailable}`}</div>
          ) : undefined}
        </Col>
        <Col xs={12} md={{ span: 9, offset: 3 }}>
          <div className={styles.label}>{`Time Limit: ${gameData.quizTimeSeconds} seconds`}</div>
        </Col>
        <Col xs={12} md={{ span: 9, offset: 3 }}>
          <div className={styles.label}>{`Coins Per Second: ${gameData.coinsPerSec}`}</div>
        </Col>
      </Row>
      {userData.cardDeck.length > 0 ? (
        <NFTGallery items={userData.cardDeck} onAddRemoveCard={handleAddRemoveCard} />
      ) : undefined}

      <div className={styles.buttonWrapper}>
        <div className={cn('button', styles.button)} onClick={onNextPhase}>
          <img src='/images/icons/quiz-icon-100.png' alt='Start Quiz' />
          Start Quiz
        </div>
      </div>
    </div>
  )
}

export default PrepPhase
