import React, { useEffect, useReducer } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import cn from 'classnames'
import { Col, Row } from 'antd'
import Axios from 'agilite-utils/axios'

import styles from './style.module.sass'

import CountdownTimer from 'components/CountdownTimer'
import Quiz from 'components/Quiz'
import LoadingIndicator from 'components/LoadingIndicator'
import Enums from 'lib/enums'
import { setQuestions } from '../-main/-reducer'
import Modal from 'components/Modal'
import QuizResultModalContent from 'components/QuizResultModalContent'
import QuizExitModalContent from 'components/QuizExitModalContent'
import { useHistory, useParams } from 'react-router-dom'
import { calculateAnswerPoints, executeAgiliteRequest } from 'lib/utils'
import { setUser } from '-core/core-reducer'
import { getUserLevelTemplate } from 'lib/templates'
import { updateUserData } from 'lib/firebase'

const QuizPhase = ({ onNextPhase, onCloseQuiz, level }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()

  const gameData = useSelector((state) => state.quiz.gameData)
  const questions = useSelector((state) => state.quiz.questions)
  const reduxUser = useSelector((state) => state.core.user)
  const sessionData = useSelector((state) => state.core.sessionData)

  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    initialized: false,
    quizSection: 'countdown',
    timerStart: false,
    coins: 0,
    score: 0,
    timer: 0,
    modalData: {
      visible: false,
      title: '',
      skillPoints: 0,
      skillCoins: 0,
      questionsTotal: 0,
      questionsCorrect: 0
    },
    exitModalVisible: false
  })

  // Runs on load of Component
  useEffect(() => {
    let axiosRef = null

    async function fetchQuestions() {
      let result = null
      let initialized = false
      let timer = 0

      try {
        if (!level.questionsTemplateKey) return // TODO: This component is loading before it should
        // Fetch Questions from Agilit-e
        axiosRef = Axios.CancelToken.source()
        result = await executeAgiliteRequest(
          axiosRef.token,
          Enums.reqActions.GET_QUIZ_QUESTIONS,
          level.questionsTemplateKey
        )

        result = result.data.data

        // Save returned questions and init Quiz
        dispatch(setQuestions(result))

        initialized = true
        timer = gameData.quizTimeSeconds
        setState({ initialized, timer })
      } catch (e) {
        console.error(e)
      }
    }

    fetchQuestions()

    return () => {
      if (axiosRef) axiosRef.cancel()
    }

    // eslint-disable-next-line
  }, [])

  // Manage Timer and Score
  useEffect(() => {
    function updateProgress() {
      let score = state.score
      let timer = state.timer

      try {
        if (!state.timerStart) return

        if (state.timer <= 0) {
          handleEndOfRound()
        } else {
          timer--
          score += gameData.coinsPerSec
          setState({ timer, score })
        }
      } catch (e) {
        console.error(e)
      }
    }

    let timeout = setTimeout(updateProgress, 1000)

    return () => {
      clearTimeout(timeout)
    }

    // eslint-disable-next-line
  }, [state.timer, state.timerStart])

  const handleCountdownTimerComplete = () => {
    try {
      // We need to start the round, timers, etc.
      setState({ quizSection: 'quiz', timerStart: true })
    } catch (e) {
      console.error(e)
    }
  }

  const handleCloseGame = () => {
    try {
      // Prompt for confirmation before closing. The game timer continues
      onCloseQuiz()
    } catch (e) {
      console.error(e)
    }
  }

  const handleEndOfRound = async (updatedQuestions) => {
    let skillCoins = 0
    let title = 'Quiz Completed 👏'

    let course = null
    let userCourse = null
    let userLevel = null
    let courseKey = null
    let errMsg = null
    let tmpUser = null
    let modalData = null
    let percentScore = null

    try {
      // Stop timers
      setState({ timerStart: false })

      if (!updatedQuestions) updatedQuestions = questions

      // First we get the coins per second, then add the points for each question answered correctly
      let { questionsTotal, questionsCorrect, skillPoints } = await calculateAnswerPoints(updatedQuestions)
      skillCoins = state.score + skillPoints
      percentScore = (questionsCorrect / questionsTotal) * 100

      if (percentScore < gameData.percentPassRequired || questionsTotal < gameData.mininumQuestionsRequired) {
        title = 'Quiz Failed 😔'
        skillPoints = 0
        skillCoins = 0
      } else {
        // Get current course lesson
        course = sessionData.courses.find((item) => item.key === params.courseId)

        if (!course) {
          errMsg = `No course found for id: ${params.courseId}`
          throw new Error(errMsg)
        }

        courseKey = `${course.category}_${course.key}`

        tmpUser = JSON.parse(JSON.stringify(reduxUser))
        tmpUser.data.state.general.skillPoints += skillPoints
        tmpUser.data.state.general.skillCoins += skillCoins

        userCourse = tmpUser.data.state.courses[courseKey]
        userCourse.skillPoints += skillPoints
        userCourse.skillCoins += skillCoins

        userLevel = userCourse.levels[params.levelId]
        userLevel.skillPoints += skillPoints
        userLevel.skillCoins += skillCoins
        userLevel.quizComplete = true

        // Get the next level if any and enable it for user
        if (level.nextLevel) {
          userCourse.levels[level.nextLevel] = getUserLevelTemplate()
        }

        await updateUserData(tmpUser.uid, tmpUser.data)
        dispatch(setUser(tmpUser))
      }

      // Launch Modal and update State
      modalData = {
        visible: true,
        title,
        skillPoints,
        skillCoins,
        questionsTotal,
        questionsCorrect
      }

      setState({ modalData })

      // Move to Next Phase
    } catch (e) {
      console.error(e)
    }
  }

  const handleOnModalClickClose = (e) => {
    try {
      setState({ modalVisible: false })
      history.replace(`/courses/${params.courseId}`)
    } catch (e) {
      console.error(e)
    }
  }

  const handleOnExitModalShow = () => {
    setState({ ...state, exitModalVisible: true })
  }

  const handleOnExitModalClickNo = () => {
    setState({ ...state, exitModalVisible: false })
  }

  const handleOnExitModalClickYes = () => {
    handleCloseGame()
  }

  const getQuizTime = () => {
    let minutes = 0
    let seconds = 0
    let secondsString = ''
    let time = null

    try {
      time = state.timer
      seconds = time // We assume less than 60 seconds

      if (time <= 60) {
        minutes = Math.floor(time / 60)
        seconds = time - minutes * 60
      }

      // Format Seconds
      if (seconds > 9) {
        secondsString = seconds.toString()
      } else {
        secondsString = `0${seconds}`
      }

      return `${minutes}:${secondsString}`
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div>
      {!state.initialized ? <LoadingIndicator /> : undefined}

      {state.initialized ? (
        <>
          {!state.modalData.visible ? (
            <>
              <div className={styles.navBar}>
                <Row justify='space-between'>
                  <Col xs={{ span: 4, offset: 1 }} md={{ span: 2, offset: 1 }}>
                    <div className={cn(styles.buttonCloseGame)} onClick={handleOnExitModalShow}>
                      <img src='/images/icons/logout-icon-100.png' alt='Cancel Quiz' />
                    </div>
                  </Col>
                  <Col xs={{ span: 9, offset: 0 }} md={{ span: 10, offset: 0 }}>
                    <div
                      className={cn(styles.label, styles.labelBottom)}
                    >{`Coins Per Second: ${gameData.coinsPerSec}`}</div>
                    <div
                      className={cn(styles.label, styles.labelBottom)}
                    >{`Pass Required: ${gameData.percentPassRequired}% (Min ${gameData.mininumQuestionsRequired} answers)`}</div>
                  </Col>
                  <Col xs={{ span: 6, offset: 0 }} md={{ span: 10, offset: 0 }}>
                    <div className={cn(styles.label, styles.labelBottom)}>{`Time: ${getQuizTime()}`}</div>
                    <div className={cn(styles.label, styles.labelBottom)}>Score: {state.score}</div>
                  </Col>
                </Row>
              </div>
              <div className={styles.quizBoard}>
                <Row>
                  <Col xs={{ span: 22, offset: 1 }} md={{ span: 22, offset: 1 }}>
                    {state.quizSection === 'countdown' ? (
                      <CountdownTimer
                        duration={gameData.phaseStartCountdownSeconds}
                        onTimerComplete={handleCountdownTimerComplete}
                      />
                    ) : undefined}
                    {state.quizSection !== 'countdown' ? <Quiz onEndOfRound={handleEndOfRound} /> : undefined}
                  </Col>
                </Row>
              </div>
              <div className={styles.footer}></div>
            </>
          ) : undefined}
          <Modal
            visible={state.modalData.visible}
            softClose={false}
            onClose={() => setState({ modalData: { visible: false } })}
          >
            <QuizResultModalContent
              title={state.modalData.title}
              skillPoints={state.modalData.skillPoints}
              skillCoins={state.modalData.skillCoins}
              questionsTotal={state.modalData.questionsTotal}
              questionsCorrect={state.modalData.questionsCorrect}
              mininumQuestionsRequired={gameData.mininumQuestionsRequired}
              onBtnClickClose={handleOnModalClickClose}
            />
          </Modal>
          <Modal
            visible={state.exitModalVisible}
            softClose={false}
            onClose={() => setState({ ...state, exitModalVisible: false })}
          >
            <QuizExitModalContent
              title='Cancel Quiz?'
              onBtnClickYes={handleOnExitModalClickYes}
              onBtnClickNo={handleOnExitModalClickNo}
            />
          </Modal>
        </>
      ) : undefined}
    </div>
  )
}

export default QuizPhase
