import React from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'

import styles from './style.module.sass'

// import Image from 'components/Image'
// import MenuNewUser from './MenuNewUser'
import BrowseCourses from './BrowseCourses'
// import ButtonSpinner from 'components/ButtonSpinner'

const Authed = ({ onLogout, disableForm }) => {
  const reduxUser = useSelector((state) => state.core.user)

  return (
    <div className={styles.section}>
      <div className={cn('container', styles.container)}>
        <div className={styles.wrap}>
          {reduxUser.data.state.general.initialized ? <BrowseCourses /> : <BrowseCourses />}
        </div>
      </div>
    </div>
  )
}

export default Authed
